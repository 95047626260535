import { Button, Modal } from 'antd'
import {
  label_mailDialogAffiliation,
  label_mrkpEmailTitle,
  label_mailDialogMail,
  label_mailDialogFromMarketplace,
  label_mailDialogFromSeller,
} from '../../Constants/Labels'
import ReactQuill from 'react-quill'
import { useEffect, useState } from 'react'
import 'react-quill/dist/quill.snow.css'
import { isNotNullOrEmpty } from '../../Helpers/GenericHelper'
import { sendEmailToMarketplace } from '../../Services/MailService'
import { sendEmailToSeller } from '../../Services/MailService'
import { isValidResult } from '../../Helpers/ResultHelper'
import { label_mailDialogSend, label_mailDialogCancel } from '../../Constants/Labels'
export type MailData = {
  isOpen: boolean
  marketplaceName?: string
  marketplaceId?: number
  sellerId?: number
  sellerName?: string
}

type MailDialogProps = {
  mailData: MailData
  onClose: () => unknown
  onEmailSent?: (toId) => unknown
  emailTo: 'seller' | 'marketplace'
  affiliationLink?: string
  contactEmail?: string
}

function MailDialog(props: MailDialogProps) {
  const [text, setText] = useState('')
  const [enableOk, setEnableOk] = useState(false)

  useEffect(() => {
    let defaultText
    if (props.emailTo === 'seller') {
      defaultText = props.contactEmail
        ? `${label_mailDialogFromMarketplace} ${label_mailDialogMail} <a href="mailto:${props.contactEmail}" target="_blank" rel="noopener noreferrer">${props.contactEmail}</a>`
        : `${label_mailDialogFromMarketplace} ${label_mailDialogAffiliation} <a href="${props.affiliationLink}" target="_blank" rel="noopener noreferrer">${props.affiliationLink}</a>`
    } else {
      defaultText = `${label_mailDialogFromSeller} ${label_mailDialogMail} <a href="mailto:${props.contactEmail}" target="_blank" rel="noopener noreferrer">${props.contactEmail}</a>`
    }
    setText(defaultText)
    setEnableOk(isNotNullOrEmpty(defaultText))
  }, [props.emailTo, props.affiliationLink, props.contactEmail])

  const sendEmail = async () => {
    const result =
      props.emailTo === 'marketplace'
        ? await sendEmailToMarketplace(props.mailData.marketplaceId, props.mailData.sellerId, text)
        : await sendEmailToSeller(props.mailData.marketplaceId, props.mailData.sellerId, text)

    if (isValidResult(result)) {
      const toId = props.emailTo === 'seller' ? props.mailData.sellerId : props.mailData.marketplaceId
      props.onEmailSent && props.onEmailSent(toId)
      props.onClose()
    }
  }

  return (
    <Modal
      centered
      width={500}
      title={`${label_mrkpEmailTitle}${props.mailData?.marketplaceName || props.mailData?.sellerName}`}
      open={props.mailData?.isOpen}
      onCancel={props.onClose}
      footer={[
        <Button key='back' onClick={props.onClose}>
          {label_mailDialogCancel}
        </Button>,
        <Button key='submit' type='primary' onClick={sendEmail} disabled={!enableOk}>
          {label_mailDialogSend}
        </Button>,
      ]}
    >
      <div style={{ width: '400px', height: '400px' }}>
        <ReactQuill
          value={text}
          style={{ width: 450, height: 350, background: 'white', textAlign: 'left' }}
          onChange={(value, delta, source, editor) => {
            setEnableOk(isNotNullOrEmpty(editor.getText().trim()))
            setText(editor.getHTML())
          }}
          modules={{
            toolbar: [
              [{ header: [1, 2, false] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['link'],
            ],
          }}
          formats={['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'image']}
        />
      </div>
    </Modal>
  )
}

export default MailDialog
