import { Checkbox, Col, Row, Select } from 'antd'
import { LocalFormItem } from './MarketplaceForm'
import { label_mrkpReturns_items } from '../../../Constants/Labels'
import { MarketplaceData, ReturnsMode } from '../../../Interfaces/MarketplaceInterfaces'
import { enum2Options } from 'antd-utils'
import { ComponentType } from '../../../Constants/SheetFieldInterface'
import { returnsFields } from '../ItemsDefinitionMarketplace'
import { UserType } from '../../../Interfaces/LoginInterfaces'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { editedMarketplaceFields } from '../../../Slices/MarketplaceSlice'
import { handleFieldChange } from '../../../Helpers/SheetHelper'
import { label_No, label_Yes } from '../../../Constants/Labels'

export default function Returns() {
  const dispatch = useAppDispatch()
  const userType: UserType = useAppSelector(state => state.present.login.userType)
  const currentMarketplace: MarketplaceData = useAppSelector(state => state.present.marketplace.currentMarketplace)
  const editedFields = useAppSelector(state => state.present.marketplace.editedFields)

  const renderSelectOptions = (items: any, labels: any) =>
    enum2Options(items).map((item: any) => (
      <Select.Option key={item.value} value={item.value}>
        {labels[item.value]}
      </Select.Option>
    ))

  return (
    <>
      {returnsFields.map((item, index) => {
        const { name, label, componentType, disabled } = item
        const isDisabled = disabled || userType !== UserType.Seller
        const isFieldEdited = Object.keys(editedFields).includes(name) ? ' modified' : ''
        const getChange = value =>
          handleFieldChange(value, name, currentMarketplace, editedFields, editedMarketplaceFields, dispatch)
        const currentValue = currentMarketplace[name] === true
        return (
          <Row key={name}>
            <Col span={8} className={'sheet-form-labels pl-4' + (index % 2 === 0 ? ' color-line' : '')}>
              {label}
            </Col>
            <Col span={16} className={'sheet-form-inputs pl-6 pr-6 pt-3' + (index % 2 === 0 ? ' color-line' : '')}>
              <LocalFormItem
                key={name}
                name={name}
                valuePropName={componentType === ComponentType.Checkbox ? 'checked' : undefined}
              >
                {componentType === ComponentType.Select && (
                  <Select
                    className={'sheet-select' + isFieldEdited}
                    disabled={isDisabled}
                    onChange={e => getChange(e.toString())}
                  >
                    {renderSelectOptions(ReturnsMode, label_mrkpReturns_items)}
                  </Select>
                )}
                {componentType === ComponentType.Checkbox &&
                  (userType !== UserType.Seller ? (
                    <Checkbox
                      className={'sheet-form-checkbox' + isFieldEdited}
                      disabled={isDisabled}
                      onChange={e => getChange(e.target.checked.toString())}
                    />
                  ) : (
                    <span className='pl-3 checkbox-seller-text'>{currentValue ? label_Yes : label_No}</span>
                  ))}
              </LocalFormItem>
            </Col>
          </Row>
        )
      })}
    </>
  )
}
