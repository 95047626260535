import { useParams } from 'react-router-dom'
import { ActionLogin, FirstLoginRequest } from '../../Interfaces/LoginInterfaces'
import { checkFirstAccessAction, firstAccess } from '../../Services/LoginService'
import { Button, Col, Form, Input, Row, Spin } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { isValidResult } from '../../Helpers/ResultHelper'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import Logosvg from '../../Svg/Logo'
import { useUserLogin } from '../../Helpers/LoginHelper'
import { label_enterText, label_password, label_repeatPassword } from '../../Constants/Labels'
import { useEffect, useState } from 'react'
import { useNavigateWrapper } from '../../Helpers/NavigateHelper'
import { useAppDispatch } from '../../hooks'
import { setLoginAction, setUsername } from '../../Slices/LoginSlice'

export default function FirstAccess() {
  const dispatch = useAppDispatch()
  const { authData } = useParams()
  const { handleUserLogin } = useUserLogin()
  const { navigateToLogin } = useNavigateWrapper()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const checkFunction = async () => {
      const response = await checkFirstAccessAction({ authenticationData: authData })
      if (isValidResult(response)) {
        if (response.payload.actionType !== ActionLogin.FirstAccess) {
          dispatch(setLoginAction(response.payload.actionType))
          dispatch(setUsername(response.payload.username))
          navigateToLogin()
        } else setIsLoading(false)
      }
    }
    checkFunction()
  }, [])

  const onFinishFailed = () => {
    console.log('errore on Finish')
  }

  const onFinish = async (values: FirstLoginRequest) => {
    values.authenticationData = authData
    const response = await firstAccess(values)
    if (isValidResult(response)) {
      handleUserLogin(response.payload.token, response.payload)
    }
  }
  return (
    <Spin spinning={isLoading}>
      <Content className='page-content login' id='page-content-login'>
        <Row justify='center' align='middle' style={{ height: '100vh' }}>
          <Col xxl={4} xl={6} lg={8} md={14} sm={16}>
            <div className='pa-5 text-center logo'>
              <Logosvg />
            </div>
            <Form
              name='basic'
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete='off'
              layout='vertical'
            >
              <Form.Item
                className='password-login-form'
                label={<Row className='login-label-custom-text'>{label_password}</Row>}
                name='password'
                rules={[{ required: true }]}
              >
                <Input.Password iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
              </Form.Item>
              <Form.Item
                className='password-login-form'
                label={<Row className='login-label-custom-text'>{label_repeatPassword}</Row>}
                name='repeat-password'
                dependencies={['password']}
                rules={[
                  { required: true },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(new Error('The new password that you entered do not match!'))
                    },
                  }),
                ]}
              >
                <Input.Password iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
              </Form.Item>
              <Form.Item>
                <Button htmlType='submit' className='login-form-button'>
                  {label_enterText}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
    </Spin>
  )
}
