import { Checkbox, CheckboxProps, Col, Input, Row, Select } from 'antd'
import { LocalFormItem } from './MarketplaceForm'
import {
  label_groupsId,
  label_italyOfficeNotes,
  label_logo,
  label_mandatoryLanguagesId,
  label_marketsId,
  label_preferableLanguagesId,
  label_sectorsId,
  label_targetsId,
  label_technologiesId,
  label_Yes,
  label_No,
  label_platformTypesId,
  label_seasonalitiesId,
} from '../../../Constants/Labels'
import { EyeOutlined } from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea'
import SelectAutocomplete from '../../Components/SelectAutocomplete'
import { BindingElement, BindingType } from '../../../Interfaces/BindingInterface'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { isValidResult } from '../../../Helpers/ResultHelper'
import { getAutocompleteData } from '../../../Services/BindingService'
import { useCallback, useMemo, useRef, useState } from 'react'
import { DefaultOptionType } from 'antd/es/select'
import { MarketplaceData } from '../../../Interfaces/MarketplaceInterfaces'
import { RcFile } from 'antd/es/upload'
import { UserType } from '../../../Interfaces/LoginInterfaces'
import { ComponentType, UploadFileType } from '../../../Constants/SheetFieldInterface'
import { marketplaceCommercialInfo, mktpUserRestrictedFields } from '../ItemsDefinitionMarketplace'
import FileUploader from '../../Components/FileUploader'
import { handleFieldChange, originalFile } from '../../../Helpers/SheetHelper'
import { editedMarketplaceFields } from '../../../Slices/MarketplaceSlice'
import { scrollToBottom } from '../../../Helpers/GenericHelper'

export type CommercialInfoProps = {
  onFileSet: (file: RcFile, type: 'logo' | 'pdf') => unknown
}

export default function CommercialInfo(props: CommercialInfoProps) {
  const dispatch = useAppDispatch()
  const sectors: BindingElement[] = useAppSelector(state => state.present.main.sectors)
  const groups: BindingElement[] = useAppSelector(state => state.present.main.groups)
  const targets: BindingElement[] = useAppSelector(state => state.present.main.targets)
  const markets: BindingElement[] = useAppSelector(state => state.present.main.markets)
  const languages: BindingElement[] = useAppSelector(state => state.present.main.languages)
  const technologies: BindingElement[] = useAppSelector(state => state.present.main.technologies)
  const platformTypes: BindingElement[] = useAppSelector(state => state.present.main.platformTypes)
  const seasonalities: BindingElement[] = useAppSelector(state => state.present.main.seasonalities)
  const userType: UserType = useAppSelector(state => state.present.login.userType)
  const currentMarketplace: MarketplaceData = useAppSelector(state => state.present.marketplace.currentMarketplace)
  const editedFields = useAppSelector(state => state.present.marketplace.editedFields)

  const [italyOfficeChecked, setItalyOfficeChecked] = useState(currentMarketplace?.italyOffice)
  const brands = useRef([...currentMarketplace.brands])

  const originalLogo = originalFile('marketplace', currentMarketplace.id, currentMarketplace.logo)
  const originalPdf = originalFile('marketplace', currentMarketplace.id, currentMarketplace.pdf)

  const [pdfUrl, setPdfUrl] = useState<string>(originalPdf)
  const [logoUrl, setLogoUrl] = useState<string>(originalLogo)

  const onChangeItalyOffice = useCallback<CheckboxProps['onChange']>(e => {
    setItalyOfficeChecked(e.target.checked)
  }, [])

  const autocompleteBrands = useCallback(
    (text: string) =>
      getAutocompleteData(BindingType.Brand, text).then(response =>
        Promise.resolve(isValidResult(response) ? response.payload.map(e => ({ label: e.name, value: e.id })) : []),
      ),
    [],
  )

  const onSelectBrand = useCallback((option: DefaultOptionType) => {
    brands.current.push({ id: parseInt(`${option.value}`), name: option.label.toString() })
  }, [])

  const getOptions = useCallback(
    //options for select
    name => {
      switch (name) {
        case label_technologiesId:
          return technologies.map(e => ({ label: e.name, value: e.id }))
        case label_marketsId:
          return markets.map(e => ({ label: e.name, value: e.id }))
        case label_sectorsId:
          return sectors.map(e => ({ label: e.name, value: e.id }))
        case label_groupsId:
          return groups.map(e => ({ label: e.name, value: e.id }))
        case label_targetsId:
          return targets.map(e => ({ label: e.name, value: e.id }))
        case label_mandatoryLanguagesId:
        case label_preferableLanguagesId:
          return languages.map(e => ({ label: e.name, value: e.id }))
        case label_platformTypesId:
          return platformTypes.map(e => ({ label: e.name, value: e.id }))
        case label_seasonalitiesId:
          return seasonalities.map(e => ({ label: e.name, value: e.id }))
        default:
          return []
      }
    },
    [technologies, markets, sectors, groups, targets, languages, platformTypes],
  )

  const generateFormItems = useCallback(
    formItems => {
      return formItems.map((item, index) => {
        const { name, label, componentType, rules, disabled, type, max, min, maxLength } = item
        const isFieldEdited = Object.keys(editedFields).includes(item.name) ? ' modified' : ''
        const isDisabled = disabled || userType !== UserType.Admin
        const getChange = value =>
          handleFieldChange(value, name, currentMarketplace, editedFields, editedMarketplaceFields, dispatch)

        const getComponent = () => {
          switch (componentType) {
            case ComponentType.Input:
              return (
                <Input
                  className={'sheet-input sheet-text' + isFieldEdited}
                  disabled={isDisabled}
                  type={type}
                  onBlur={e => getChange(e.target.value)}
                  max={max}
                  min={min}
                  maxLength={maxLength}
                />
              )
            case ComponentType.TextArea:
              return (
                <TextArea
                  className={'sheet-text-area sheet-text' + isFieldEdited}
                  disabled={isDisabled}
                  onBlur={e => getChange(e.target.value)}
                  maxLength={maxLength}
                />
              )

            case ComponentType.SelectMultiple:
              return (
                <Select
                  className={'sheet-select-multiple' + isFieldEdited}
                  mode='multiple'
                  allowClear
                  options={getOptions(name)}
                  optionFilterProp='label'
                  disabled={isDisabled}
                  onChange={e => getChange(e.toString())}
                  maxTagCount={'responsive'}
                />
              )
            case ComponentType.SelectAutoComplete:
              return (
                <SelectAutocomplete
                  className={'sheet-select-autocomplete' + isFieldEdited}
                  mode='multiple'
                  initialvalues={brands.current?.map(e => ({ label: e.name, value: e.id }))}
                  onAutocompleteSearch={autocompleteBrands}
                  onSelectOption={onSelectBrand}
                  disabled={isDisabled}
                  onChange={e => getChange(e.toString())}
                />
              )
            case ComponentType.Checkbox:
              return userType !== UserType.Seller ? (
                <Checkbox
                  checked={italyOfficeChecked}
                  className={'sheet-form-checkbox' + isFieldEdited}
                  disabled={isDisabled}
                  onChange={e => {
                    onChangeItalyOffice(e)
                    getChange(e.target.checked.toString())
                  }}
                />
              ) : (
                <span className='pl-3 checkbox-seller-text'>{italyOfficeChecked ? label_Yes : label_No}</span>
              )

            case ComponentType.Upload:
              return item.name === UploadFileType.Pdf ? (
                <FileUploader
                  setFileUrl={setPdfUrl}
                  fileUrl={pdfUrl}
                  onFileSet={props.onFileSet}
                  formUserType={UserType.Marketplace}
                  fileType={UploadFileType.Pdf}
                />
              ) : (
                <FileUploader
                  setFileUrl={setLogoUrl}
                  fileUrl={logoUrl}
                  onFileSet={props.onFileSet}
                  formUserType={UserType.Marketplace}
                  fileType={UploadFileType.Logo}
                />
              )

            default:
              return <Input />
          }
        }

        const isFieldVisible =
          (name === label_italyOfficeNotes && !italyOfficeChecked) ||
          (name === label_italyOfficeNotes &&
            italyOfficeChecked &&
            currentMarketplace.italyOfficeNotes === '' &&
            userType === UserType.Seller) ||
          (name === 'italyOffice' &&
            italyOfficeChecked &&
            currentMarketplace.italyOfficeNotes !== '' &&
            userType === UserType.Seller) ||
          (name === label_logo && userType === UserType.Seller) ||
          (name === 'id' && userType !== UserType.Admin) ||
          (name === 'username' && userType !== UserType.Admin) ||
          (name === 'privacyConsent' && userType !== UserType.Admin) ||
          (name === 'brandsId' && userType !== UserType.Admin) ||
          (name === 'otherBrands' && userType !== UserType.Admin)
            ? false
            : !mktpUserRestrictedFields.includes(name) || userType !== UserType.Seller

        return (
          isFieldVisible && (
            <Row>
              <Col
                span={8}
                className={
                  'sheet-form-labels pl-4 pr-4 d-flex justify-space-between' + (index % 2 === 0 ? ' color-line' : '')
                }
              >
                {label}
                {mktpUserRestrictedFields.includes(name) && userType !== UserType.Seller && (
                  <EyeOutlined onClick={scrollToBottom} />
                )}
              </Col>
              <Col span={16} className={'sheet-form-inputs pl-6 pr-6 pt-3' + (index % 2 === 0 ? ' color-line' : '')}>
                <LocalFormItem
                  key={name}
                  name={name}
                  rules={rules ? [{ required: true }] : null}
                  valuePropName={componentType === ComponentType.Checkbox ? 'checked' : 'value'}
                >
                  {getComponent()}
                </LocalFormItem>
              </Col>
            </Row>
          )
        )
      })
    },
    [
      italyOfficeChecked,
      autocompleteBrands,
      getOptions,
      onChangeItalyOffice,
      onSelectBrand,
      props.onFileSet,
      userType,
      logoUrl,
      pdfUrl,
      editedFields,
      currentMarketplace,
      dispatch,
    ],
  )

  const formItems = useMemo(() => generateFormItems(marketplaceCommercialInfo), [generateFormItems])

  return <>{formItems}</>
}
