import { createRequest, createRequestNoStringifyBody, isError, notifyError } from '../Helpers/GenericHelper'
import { InvalidResult, ValidResult } from '../Helpers/ResultHelper'
import { getTokenFromSessionStorage } from '../Helpers/TokenHelper'

export enum UploadFile {
  MarketplaceLogo,
  MarketplacePdf,
  SellerPdf,
}

export const uploadFile = async (file: File, type: UploadFile, idEntity: number) => {
  const url = `/api/upload?type=${type}&idEntity=${idEntity}`
  const token = getTokenFromSessionStorage()

  const formData = new FormData()
  formData.append('formFile', file)
  const result = await createRequestNoStringifyBody(url, 'POST', formData, token, true, true)
  if (isError(result)) {
    notifyError('uploadFile', result)
    return InvalidResult()
  } else return ValidResult(result.payload)
}

export const deleteFile = async (fileName: string, type: UploadFile, idEntity: number) => {
  const url = `/api/upload?type=${type}&idEntity=${idEntity}&fileName=${fileName}`
  const token = getTokenFromSessionStorage()

  const result = await createRequest(url, 'DELETE', null, token, true, true)
  if (isError(result)) {
    notifyError('deleteFile', result)
    return InvalidResult()
  } else return ValidResult(result)
}
