export type SafeValidResult<T> = { _valid: true; payload: T }
export type SafeInvalidResult = { _valid: false }
export type SafeResult<T> = SafeInvalidResult | SafeValidResult<T>

export function ValidResult<T>(data: T): SafeResult<T> {
  return { _valid: true, payload: data }
}
export function InvalidResult<T>(): SafeResult<T> {
  return { _valid: false }
}
export function isValidResult<T>(result: SafeResult<T>): result is SafeValidResult<T> {
  return result._valid
}
