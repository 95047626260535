import { createSlice } from '@reduxjs/toolkit'

export const sellerSlice = createSlice({
  name: 'seller',
  initialState: {
    selected: null,
    editedFields: {},
    currentPage: '',
  },
  reducers: {
    setSeller: (state, action) => {
      state.selected = action.payload
    },
    editedSellerFields: (state, action) => {
      const fieldName = action.payload.fieldName
      const content = action.payload.content
      const alreadyEditedFields = state.editedFields.hasOwnProperty(fieldName)
      if (alreadyEditedFields) {
        if (content === state.selected[fieldName].toString()) {
          const updatedEditedFields = { ...state.editedFields }
          delete updatedEditedFields[fieldName]
          state.editedFields = updatedEditedFields
        } else {
          const alreadyEditedFields = { ...state.editedFields }
          alreadyEditedFields[fieldName] = content
          state.editedFields = alreadyEditedFields
        }
      } else {
        state.editedFields = {
          ...state.editedFields,
          [fieldName]: content,
        }
      }
    },
    resetSellerEdit: state => {
      state.editedFields = {}
    },
    setSellerCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
  },
})

export const { setSellerCurrentPage, setSeller, editedSellerFields, resetSellerEdit } = sellerSlice.actions
export default sellerSlice.reducer
