import './App.css'
import './Styles/DefaultIndex.css'
import { CLIENT_ROUTES } from './Constants/ClientRoutes'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './Styles/StandardStyle.css'
import './Styles/Icon.css'
import './Styles/Font.css'
import './Styles/Header.css'
import './Styles/Footer.css'
import './Styles/Login.css'
import './Styles/Table.css'
import './Styles/Sheet.css'

function App() {
  const router = createBrowserRouter(CLIENT_ROUTES)

  return <RouterProvider router={router} />
}

export default App
