import { createRequest, isError, notifyError } from '../Helpers/GenericHelper'
import { InvalidResult, ValidResult } from '../Helpers/ResultHelper'
import { getTokenFromSessionStorage } from '../Helpers/TokenHelper'
import { Filter } from '../Interfaces/FilterInterface'
import { SellerData, SellerMarketplaceVisibilityResponse, SellerSaveResponse } from '../Interfaces/SellerInterfaces'

export const saveSellerData = async (data: SellerData) => {
  const url = `/api/seller`
  const token = getTokenFromSessionStorage()
  const result = await createRequest<SellerSaveResponse>(url, 'POST', data, token, true, true)
  if (isError(result)) {
    notifyError('saveSellerData', result)
    return InvalidResult<SellerSaveResponse>()
  } else return ValidResult(result.payload)
}

export const getSellerData = async (id: number) => {
  const url = `/api/seller/${id}/data`
  const token = getTokenFromSessionStorage()

  const result = await createRequest<SellerData>(url, 'GET', null, token, true, true)
  if (isError(result)) {
    notifyError('getSellerData', result)
    return InvalidResult<SellerData>()
  } else return ValidResult(result.payload)
}
export const getSellerList = async (filter?: Filter) => {
  const url = `/api/seller/getlist`
  const token = getTokenFromSessionStorage()
  const body = filter || { filterNameOrBrand: '' }

  const result = await createRequest<SellerData[]>(url, 'POST', body, token, true, true)
  if (isError(result)) {
    notifyError('getSellerList', result)
    return InvalidResult<SellerData[]>()
  } else return ValidResult(result.payload)
}

export const saveMarketplaceVisibility = async (idSeller: number, idMarketplace: number, visible: boolean) => {
  const url = `/api/seller/${idSeller}/visibility?marketplace=${idMarketplace}&visible=${visible}`
  const token = getTokenFromSessionStorage()
  const result = await createRequest<SellerMarketplaceVisibilityResponse>(url, 'POST', null, token, true, true)
  if (isError(result)) {
    notifyError('saveMarketplaceVisibility', result)
    return InvalidResult<SellerMarketplaceVisibilityResponse>()
  } else return ValidResult(result.payload)
}

export const saveAllMarketplaceVisibility = async (idSeller: number, visible: boolean) => {
  const url = `/api/seller/${idSeller}/visibilityall?visible=${visible}`
  const token = getTokenFromSessionStorage()
  return createRequest<SellerMarketplaceVisibilityResponse>(url, 'POST', null, token, true, true).then(result => {
    if (isError(result)) {
      notifyError('saveMarketplaceVisibility', result)
      return InvalidResult<SellerMarketplaceVisibilityResponse>()
    } else return ValidResult(result.payload)
  })
}

export const enableSeller = async (id: number, value: boolean) => {
  const url = `/api/seller/enable?id=${id}&value=${value}`
  const token = getTokenFromSessionStorage()

  const result = await createRequest(url, 'POST', null, token, true, true)
  if (isError(result)) {
    notifyError('enableSeller', result)
    return InvalidResult<SellerData[]>()
  } else return ValidResult(result.payload)
}
