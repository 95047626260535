import { Content } from 'antd/es/layout/layout'
import { Col, Row, Button } from 'antd'
import { useNavigateWrapper } from '../../Helpers/NavigateHelper'
import {
  label_mrkpFilter_options,
  label_sellerBrands,
  label_sellerLEnabled,
  label_sellerName,
  label_sellerSectors,
  label_sellerNShops,
  label_mrkpActivation,
  label_mrkpSentEmail,
  label_mrkpSendFirstEmail,
  label_mrkpAffiliationLink,
  label_mrkpPrivacyConsent,
} from '../../Constants/Labels'
import SellerTable from './Components/SellerTable'
import { useEffect, useMemo, useState } from 'react'
import { getSellerList } from '../../Services/SellerService'
import { isValidResult } from '../../Helpers/ResultHelper'
import { SellerData, SellerDataKeys } from '../../Interfaces/SellerInterfaces'
import { ColumnType } from 'antd/es/table'
import { UserType } from '../../Interfaces/LoginInterfaces'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { setMarketplace, setMarketplaceCurrentPage } from '../../Slices/MarketplaceSlice'
import { MENUKEYS } from '../../Constants/ClientRoutes'
import { setAdminCurrentPage } from '../../Slices/AdminSlice'
import { marketplaceAuthorizedUsers } from '../Marketplace/ItemsDefinitionMarketplace'
import { enum2Options } from 'antd-utils'
import { FilterOptions, MarketplaceData } from '../../Interfaces/MarketplaceInterfaces'
import { CheckOutlined, MailOutlined } from '@ant-design/icons'
import Search from 'antd/es/input/Search'
import { isNotNullOrEmpty, isValidDate } from '../../Helpers/GenericHelper'
import { BindingElement } from '../../Interfaces/BindingInterface'
import { smColWidth, mdColWidth, lgColWidth, xlColWidth } from '../../Constants/TableConst'
import { MarketplaceActivations } from '../../Interfaces/MarketplaceActivations'
import { MailData } from '../Components/MailDialog'
import { getMarketplaceData } from '../../Services/MarketplaceService'
import MailDialog from '../Components/MailDialog'

export default function Sellers() {
  const dispatch = useAppDispatch()
  const userType: UserType = useAppSelector(state => state.present.login.userType)
  const entityId: number = useAppSelector(state => state.present.login.entityId)

  const currentMarketplace: MarketplaceData = useAppSelector(state => state.present.marketplace.currentMarketplace)
  const sectors: BindingElement[] = useAppSelector(state => state.present.main.sectors)

  const { navigateToSellerSheet } = useNavigateWrapper()
  const [dataList, setDataList] = useState<SellerData[] | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [activationRows, setActivationRows] = useState<MarketplaceActivations[]>([])
  const [mailData, setMailData] = useState<MailData>(null)
  const isUserAuthorized = marketplaceAuthorizedUsers.includes(userType)

  const onSearch = text => {
    setIsLoading(true)
    getSellerList({ filterNameOrBrand: text }).then(data => {
      if (isValidResult(data)) setDataList(data.payload)
      else setDataList([])
      setIsLoading(false)
    })
  }

  const onChangeSearch = e => !isNotNullOrEmpty(e) && onSearch('')

  useEffect(() => {
    getSellerList().then(data => {
      if (isValidResult(data)) setDataList(data.payload)
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    if (currentMarketplace) setActivationRows(currentMarketplace.activations)
  }, [currentMarketplace])

  useEffect(() => {
    if (userType === UserType.Marketplace) {
      dispatch(setMarketplaceCurrentPage(MENUKEYS.Sellers))
    }
    if (userType === UserType.Admin) {
      dispatch(setAdminCurrentPage(MENUKEYS.Sellers))
    }
  }, [dispatch, userType])

  useEffect(() => {
    if (userType === UserType.Marketplace && entityId) {
      getMarketplaceData(entityId).then(mrkp => {
        if (isValidResult(mrkp)) dispatch(setMarketplace(mrkp.payload))
      })
      dispatch(setMarketplaceCurrentPage(MENUKEYS.Sellers))
    }
    if (userType === UserType.Admin) {
      dispatch(setAdminCurrentPage(MENUKEYS.Sellers))
    }
  }, [entityId, userType, dispatch])

  const dataTable = useMemo(() => {
    const mappedList = dataList?.map(d => ({
      ...d,
      sectorList: d.sectors?.map(e => e.name).join(',') || '',
      brandList: d.brands?.map(e => e.name).join(',') || '',
    }))
    return mappedList
  }, [dataList])

  interface LocalColumn extends Exclude<ColumnType<SellerData>, 'dataIndex' | 'key'> {
    dataIndex: SellerDataKeys
    key: SellerDataKeys
  }

  const columns: LocalColumn[] = useMemo(
    () =>
      [
        {
          title: `${label_sellerName}`,
          dataIndex: 'businessName',
          key: 'businessName',
          render: (text, record) => <a onClick={() => navigateToSellerSheet(record.id)}>{text}</a>,
          sorter: (a, b) => {
            return a.businessName.localeCompare(b.businessName)
          },
          width: mdColWidth,
        },
        userType === UserType.Admin
          ? {
              title: `${label_sellerLEnabled}`,
              dataIndex: 'isEnabled',
              key: 'isEnabled',
              render: (_text, record) => (record.isEnabled ? <CheckOutlined /> : null),
              onFilter: (value, record) => record.isEnabled === (value === FilterOptions.Yes),
              filters: enum2Options(FilterOptions).map((item: any) => ({
                value: item.value,
                text: label_mrkpFilter_options[item.value],
              })),
              width: smColWidth,
              className: 'text-center',
            }
          : null,
        userType === UserType.Admin
          ? {
              title: `${label_mrkpPrivacyConsent}`,
              dataIndex: 'privacyConsent',
              key: 'privacyConsent',
              render: (_text, record) => (record.privacyConsent ? <CheckOutlined /> : null),
              onFilter: (value, record) => record.privacyConsent === (value === FilterOptions.Yes),
              filters: enum2Options(FilterOptions).map((item: any) => ({
                value: item.value,
                text: label_mrkpFilter_options[item.value],
              })),
              width: smColWidth,
              className: 'text-center',
            }
          : null,

        {
          title: `${label_sellerNShops}`,
          dataIndex: 'shopsQty',
          key: 'shopsQty',
          render: text => {
            return <p> {text}</p>
          },
          sorter: (a, b) => {
            return a.shopsQty - b.shopsQty
          },
          width: smColWidth,
        },
        {
          title: `${label_sellerSectors}`,
          dataIndex: 'sectorList',
          key: 'sectorList',
          render: text => {
            return <p> {text}</p>
          },
          onFilter: (value, record) => record.sectorList.includes(value),
          filters: sectors.map((item: any) => ({
            value: item.name,
            text: item.name,
          })),
          width: xlColWidth,
        },
        {
          title: `${label_sellerBrands}`,
          dataIndex: 'brandList',
          key: 'brandList',
          width: xlColWidth,
        },
        userType === UserType.Marketplace && currentMarketplace
          ? {
              title: `${label_mrkpActivation}`,
              dataIndex: 'activations',
              key: 'activations',
              render: (_text, record) => {
                const activationRow = activationRows.find(e => e.sellerId == record.id)
                return activationRow?.activation && isValidDate(activationRow.activation) ? (
                  <CheckOutlined />
                ) : currentMarketplace.contactEmail ? (
                  <>
                    <Button
                      icon={
                        <MailOutlined
                          onClick={() => {
                            setMailData({
                              isOpen: true,
                              marketplaceId: currentMarketplace.id,
                              sellerId: record.id,
                              sellerName: record.businessName,
                            })
                          }}
                        />
                      }
                    ></Button>
                    {activationRow?.sentEmail > 0 ? (
                      <>
                        {activationRow.sentEmail} {label_mrkpSentEmail}
                      </>
                    ) : (
                      <>{label_mrkpSendFirstEmail}</>
                    )}
                  </>
                ) : (
                  <>
                    {currentMarketplace.affiliationLink ? (
                      <a href={currentMarketplace.affiliationLink} target='_blank' rel='noopener noreferrer'>
                        {label_mrkpAffiliationLink}
                      </a>
                    ) : null}
                  </>
                )
              },

              // sorter: (a, b) => a.name.length - b.name.length,
              width: lgColWidth,
              className: 'columns-bg-4 fixedR-column-2',
            }
          : null,
      ].filter(e => e !== null) as LocalColumn[],
    [userType, currentMarketplace, activationRows, dispatch],
  )

  return isUserAuthorized ? (
    <>
      <Content className='page-content Sellers pa-6 bg-white ' id='page-content-Sellers'>
        <Row>
          <Col className='pl-6 pr-6'>
            <Search
              className='pr-6 pt-5 pl-6'
              style={{ width: 800 }}
              placeholder={`${label_sellerName}/${label_sellerBrands}`.toLowerCase()}
              onSearch={onSearch}
              enterButton
              allowClear
              onChange={onChangeSearch}
            />
          </Col>
        </Row>
        <Row>
          <Col className='pa-6'>
            <SellerTable columns={columns} sellersData={dataTable} isLoading={isLoading} />
          </Col>
        </Row>
      </Content>
      <MailDialog
        mailData={mailData}
        emailTo='seller'
        affiliationLink={currentMarketplace?.affiliationLink}
        contactEmail={currentMarketplace?.contactEmail}
        onClose={() => setMailData({ isOpen: false })}
        onEmailSent={sellerId => {
          const updated: MarketplaceActivations[] = JSON.parse(JSON.stringify(activationRows))
          const activationRow = updated.find(x => x.sellerId == sellerId)
          if (activationRow) {
            activationRow.sentEmail += 1
          } else {
            updated.push({
              sentEmail: 1,
              marketplaceId: currentMarketplace.id,
              sellerId: sellerId,
            })
          }
          setActivationRows(updated)
        }}
      />
    </>
  ) : null
}
