import { BindingElement } from './BindingInterface'
import { MarketplaceActivations } from './MarketplaceActivations'

export interface MarketplaceData {
  id: number
  hubId: number
  name: string
  url: string
  year: string
  shortStory: string
  registeredOffice: string
  italyOffice: boolean
  italyOfficeNotes: string
  contact: string
  contactEmail: string
  accountingEmails: string
  techTeamEmails: string
  genericEmail: string
  otherBrands: string
  targetSegment: string
  logo: string
  pdf: string
  requirements: string
  hasDiscounts: boolean
  hasComminsions: boolean
  hasFixedFeeForItem: boolean
  hasFixedFeeForMonth: boolean
  hasMonthlyExpenses: boolean
  hasPackaging: boolean

  filterOptions: FilterOptions
  shippingMode: ShippingMode
  groupageAddress: string
  shippingManaged: ShippingManagedBy
  shippingNotes: string
  hasDashboard: boolean
  billingFrequency: BillingFrequency
  billingNotes: string
  paymentConditions: string
  depositRetailer: ThreeState
  depositNotes: string
  kpi: ThreeState
  kpiNotes: string
  penalty: ThreeState
  penaltyNotes: string
  hasReturns: boolean
  returnsMode: ReturnsMode
  hasFaultyReturns: boolean
  faultyReturnsMode: ReturnsMode
  affiliationLink: string
  shippingDocumentNotes: string

  technologiesId: number[]
  sectorsId: number[]
  groupsId: number[]
  preferableLanguagesId: number[]
  mandatoryLanguagesId: number[]
  brandsId: number[]
  targetsId: number[]
  marketsId: number[]
  shippingDocumentsId: number[]
  platformTypesId: number[]
  seasonalitiesId: number[]

  brands: BindingElement[]
  sectors: BindingElement[]
  groups: BindingElement[]
  markets: BindingElement[]
  targets: BindingElement[]
  platformTypes: BindingElement[]
  shippingDocuments: BindingElement[]
  seasonalities: BindingElement[]

  sectorList: string
  brandList: string
  marketList: string
  groupList: string
  seasonalityList: string
  targetList: string
  platformTypeList: string

  activationDate: Date
  activations: MarketplaceActivations[]
  visibility: boolean

  isEnabled: boolean
  username: string
  privacyConsent: boolean
}

export type MarketplaceDataKeys = keyof MarketplaceData

export interface MarketplaceSaveResponse {
  id: number
}

export interface MarketHub {
  marketName: string
  idHub: number
}

export interface MarketplaceCredentials {
  username: string
  idHub: number
}

export interface MarketplaceVisibility {
  marketplaceId: number
  visible: boolean
}

// export enum PlatformType {
//   B2COpen,
//   B2CPrivate,
//   B2BOpen,
//   B2BPrivate,
// }

export enum FilterOptions {
  Yes,
  No,
}

export enum ShippingMode {
  Groupage,
  FinalAddress,
}

export enum ShippingManagedBy {
  Marketplace,
  Seller,
  Other,
}

// export enum ShippingDocument {
//   PackingList,
//   AirwayBill,
//   ExportationDocument,
//   Invoice,
// }

export enum BillingFrequency {
  ByOrder,
  Daily,
  Weekly,
  Monthly,
  Other,
}

export enum ThreeState {
  NO,
  YES,
  Other,
}

export enum ReturnsMode {
  Marketplace,
  Seller,
}
