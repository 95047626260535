import { Col, Image, Row } from 'antd'
import React from 'react'
import {
  AddressFooterText,
  AtelierFooterText,
  EmailAddress,
  PlaceFooterText,
  TelephoneNumber,
} from '../Constants/GeneralTerms'

function Footer(props) {
  return (
    <Row className={'footer-container pa-5'} justify={'space-between'} align={'middle'}>
      <Col className='footer-text'>
        <div>
          <Image
            src='/Zuc_Orizz_A.png'
            alt='Zucchetti Logo'
            width={200}
            preview={false} // disables the image preview on click
          />
        </div>
        {AtelierFooterText}
      </Col>
      <Col>
        <Row>
          <Col className='footer-text footer-text-border pr-4'>
            <div>{AddressFooterText}</div>
            <div>{PlaceFooterText}</div>
          </Col>
          <Col className='footer-text pl-4'>
            <div>{TelephoneNumber}</div>
            <div>{EmailAddress}</div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Footer
