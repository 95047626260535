import { Table } from 'antd'
import { MarketplaceData } from '../../../Interfaces/MarketplaceInterfaces'
import { MrkpColumnsType } from '../Marketplaces'

type MarketplaceTableProps = {
  columns: MrkpColumnsType
  marketplacesData: null | MarketplaceData[]
  isLoading: boolean
}
export default function MarketplaceTable(props: MarketplaceTableProps) {
  return (
    <Table
      rowKey={'id'}
      rowClassName={(record, index) => (index % 2 === 0 ? ' color-line' : ' empty-line')}
      id='marketplace-table'
      className='pr-6 pt-5 pl-6'
      columns={props.columns}
      dataSource={props.marketplacesData}
      showSorterTooltip={false}
      loading={props.isLoading}
      scroll={{ x: 'max-content', y: 'calc(100vh - 250px)' }}
      pagination={{ position: ['topRight', 'bottomRight'] }}
    />
  )
}
