import { Select, SelectProps, Spin } from 'antd'
import { useMemo, useRef, useState } from 'react'
import debounce from 'lodash/debounce'
import { isNotNullOrEmpty } from '../../Helpers/GenericHelper'
import { DefaultOptionType } from 'antd/es/select'

type SelectAutocompleteFormProps = SelectProps & {
  initialvalues: DefaultOptionType[]
  onAutocompleteSearch: (text: string) => Promise<DefaultOptionType[]>
  onSelectOption: (option: DefaultOptionType) => unknown
}
export default function SelectAutocomplete(props: SelectAutocompleteFormProps) {
  const [options, setOptions] = useState([])
  const [fixedOptions, setFixedOptions] = useState(props.initialvalues)
  const [fetching, setFetching] = useState(false)
  const fetchRef = useRef(0)
  const [searchedValue, setSearchedValue] = useState('')

  const debounceFetcher = useMemo(() => {
    const loadOptions = text => {
      if (!isNotNullOrEmpty(text)) {
        return
      }
      fetchRef.current += 1
      const fetchId = fetchRef.current
      if (options.length !== 0) {
        setOptions([])
      }
      props.onAutocompleteSearch(text).then(result => {
        if (fetchId !== fetchRef.current) {
          return
        }
        setOptions(result)
        setFetching(false)
        setSearchedValue('')
      })
    }
    return debounce(loadOptions, 800)
  }, [options.length])

  const addOnFixed = optionId => {
    const selected = options.find(i => i.value === optionId)
    if (fixedOptions.findIndex(e => e.value === optionId) < 0) {
      setFixedOptions([...fixedOptions, selected])
      props.onSelectOption(selected)
    }
  }
  const concatOptions = () =>
    options.filter(e => fixedOptions.findIndex(i => i.value === e.value) < 0).concat(fixedOptions)

  return (
    <Select
      {...props}
      options={concatOptions()}
      onSearch={value => {
        if (isNotNullOrEmpty(value)) {
          setFetching(true)
          setSearchedValue(value)
          debounceFetcher(value)
        }
      }}
      onSelect={addOnFixed}
      notFoundContent={fetching ? <Spin size='small' /> : null}
      searchValue={searchedValue}
      maxTagCount={'responsive'}
    ></Select>
  )
}
