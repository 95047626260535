import { Button, Col, Collapse, Form, Row } from 'antd'
import { MarketplaceData, MarketplaceDataKeys } from '../../../Interfaces/MarketplaceInterfaces'
import {
  label_save,
  label_mrkpCommercialInfo,
  label_mrkpConditions,
  label_mrkpInvoicing,
  label_mrkpShippingMethods,
  label_mrkpReturnsHandling,
  label_mrkpEnabled,
  label_mrkpDisabled,
  label_mrkpConditionsRetailer,
} from '../../../Constants/Labels'
import { useEffect, useMemo, useState } from 'react'

import type { CollapseProps } from 'antd'
import { RcFile } from 'antd/es/upload'
import CommercialInfo from './CommercialInfo'
import TypedFormItem from '../../Components/TypedFormItem'
import Conditions from './Conditions'
import Shipping from './Shipping'
import Invoicing from './Invoicing'
import Returns from './Returns'
import { UserType } from '../../../Interfaces/LoginInterfaces'
import { useAppSelector } from '../../../hooks'
import { isObjectEmpty } from '../../../Helpers/GenericHelper'
import { CheckOutlined } from '@ant-design/icons'
import { useNavigateWrapper } from '../../../Helpers/NavigateHelper'

export type MarketplaceFormProps = {
  currentMarketplace: MarketplaceData | null
  onSubmit: (values: MarketplaceData, logo: RcFile, pdf: RcFile) => unknown
  onEnable: (value: boolean) => unknown
}

export const LocalFormItem = TypedFormItem<MarketplaceDataKeys>
export default function MarketplaceForm(props: MarketplaceFormProps) {
  const [form] = Form.useForm()
  const [tempLogo, setTempLogo] = useState(null)
  const [tempPdf, setTempPdf] = useState(null)
  const { navigateToPrivacy } = useNavigateWrapper()

  const userType: UserType = useAppSelector(state => state.present.login.userType)
  const editedFields = useAppSelector(state => state.present.marketplace.editedFields)
  const marketplaceSheetCollapseItem = (label: string) => {
    return (
      <Row justify={'space-between'} align={'middle'} className='sheet-form-header'>
        <Col span={8} className='sheet-collapse-header pl-4 pr-0'>
          {label}
        </Col>
      </Row>
    )
  }
  const enableButton = () => {
    const isEnabled = props.currentMarketplace.isEnabled
    return userType === UserType.Admin ? (
      <>
        <div
          className={`sheet-enabled-div ${isEnabled ? 'sheet-enabled-text' : 'sheet-disabled-text'}`}
          onClick={event => {
            props.onEnable(!isEnabled)
            event.stopPropagation()
          }}
        >
          {isEnabled ? label_mrkpEnabled : label_mrkpDisabled}
          <CheckOutlined className={isEnabled ? 'sheet-enabled-icon' : 'sheet-disabled-icon'} />
        </div>
      </>
    ) : null
  }
  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: marketplaceSheetCollapseItem(label_mrkpCommercialInfo),
      children: (
        <CommercialInfo
          onFileSet={(file, type) => {
            type === 'logo' ? setTempLogo(file) : setTempPdf(file)
          }}
        />
      ),
      extra: enableButton(),
      className: 'columns-bg-1',
    },
    {
      key: '2',
      label: marketplaceSheetCollapseItem(
        userType === UserType.Marketplace ? label_mrkpConditions : label_mrkpConditionsRetailer,
      ),
      children: <Conditions />,
      className: 'columns-bg-2',
    },
    {
      key: '3',
      label: marketplaceSheetCollapseItem(label_mrkpShippingMethods),
      children: <Shipping />,
      className: 'columns-bg-3',
    },
    {
      key: '4',
      label: marketplaceSheetCollapseItem(label_mrkpInvoicing),
      children: <Invoicing />,
      className: 'columns-bg-3',
    },
    {
      key: '5',
      label: marketplaceSheetCollapseItem(label_mrkpReturnsHandling),
      children: <Returns />,
      className: 'columns-bg-3',
    },
  ]

  useEffect(() => {
    //fix to properly show data after submit
    form.setFieldsValue(props.currentMarketplace)
  }, [form, props.currentMarketplace])

  const submitButton = useMemo(() => {
    return (
      <Row justify={'end'} style={{ width: '100%' }} className='pt-3'>
        <Col span={10}>
          <Form.Item>
            {userType === UserType.Admin ? (
              <Button
                htmlType='submit'
                className={'sheet-form-button' + (!isObjectEmpty(editedFields) ? ' active' : ' disabled')}
                disabled={isObjectEmpty(editedFields)}
              >
                {label_save}
              </Button>
            ) : null}
            {userType === UserType.Marketplace ? (
              <Button type='link' className='login-forgot-password ' onClick={navigateToPrivacy}>
                Privacy
              </Button>
            ) : null}
          </Form.Item>
        </Col>
      </Row>
    )
  }, [editedFields])

  return (
    <Form
      form={form}
      name='basic'
      initialValues={props.currentMarketplace}
      onFinish={values => props.onSubmit(values, tempLogo, tempPdf)}
      autoComplete='off'
      layout='vertical'
      className='marketplace-form'
    >
      <Collapse items={items} defaultActiveKey={['1']} />
      {submitButton}
    </Form>
  )
}
