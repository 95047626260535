import { Button, Col, Form, Input, Row } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { ActionLogin, LoginRequest } from '../../Interfaces/LoginInterfaces'
import { checkUsername, registerPassword, requestToken } from '../../Services/LoginService'
import Logosvg from '../../Svg/Logo'
import { isValidResult } from '../../Helpers/ResultHelper'
import { useRef, useState } from 'react'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import Footer from '../../Components/Footer'
import ArrowLogin from '../../Svg/ArrowLogin'
import { useUserLogin } from '../../Helpers/LoginHelper'
import {
  label_emailLogin,
  label_enterText,
  label_forgotPassword,
  label_headerMarketplaceHUBCommunity,
  label_password,
  label_repeatPassword,
} from '../../Constants/Labels'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { setLoginAction, setUser, setUsername } from '../../Slices/LoginSlice'
import { useNavigateWrapper } from '../../Helpers/NavigateHelper'

export default function Login() {
  const loginAction: ActionLogin = useAppSelector(state => state.present.login.action)
  const username: string = useAppSelector(state => state.present.login.username)
  const { navigateToForgotPassword } = useNavigateWrapper()

  const { handleUserLogin } = useUserLogin()
  const dispatch = useAppDispatch()

  const formRef = useRef(null)

  const onFinishFailed = err => {
    console.log('username', username)
    console.log('errore on Finish ' + err)
  }

  const onFinish = async (values: LoginRequest) => {
    switch (loginAction) {
      case ActionLogin.Username:
        const usernameResponse = await checkUsername(values)
        if (isValidResult(usernameResponse)) {
          dispatch(setLoginAction(usernameResponse.payload.actionType))
          dispatch(setUsername(values.username))
        }
        break
      case ActionLogin.InsertPassword:
      case ActionLogin.RegisterPassword:
        const registerResponse =
          loginAction === ActionLogin.InsertPassword ? await requestToken(values) : await registerPassword(values)
        if (isValidResult(registerResponse)) {
          handleUserLogin(registerResponse.payload.token, registerResponse.payload)
        }
        break
    }
  }

  const handleArrowClick = () => {
    formRef.current.submit()
  }

  const onForgotPassword = () => {
    navigateToForgotPassword()
  }

  return (
    <Content className='page-content login' id='page-content-login'>
      <Row className='navbar-container' justify={'center'}>
        <Col className='ant-menu-title-content' span={24}>
          {label_headerMarketplaceHUBCommunity}
        </Col>
      </Row>
      <Row justify='center' align='middle' style={{ minHeight: '50vh' }} className='login-background'>
        <Col xxl={4} xl={6} lg={8} md={14} sm={16}>
          <div className='pa-5 text-center logo'>
            <Logosvg />
          </div>

          <Form
            name='basic'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
            layout='vertical'
            ref={formRef}
          >
            <Form.Item
              name='username'
              label={<Row className='login-label-custom-text'>{label_emailLogin}</Row>}
              rules={[{ required: true }]}
              hidden={loginAction !== ActionLogin.Username}
              initialValue={username}
            >
              <Input
                placeholder='email@gmail.com'
                addonAfter={
                  <div onClick={() => handleArrowClick()} className='cursor-pointer'>
                    <ArrowLogin />
                  </div>
                }
              />
            </Form.Item>

            <Form.Item
              className='password-login-form'
              label={<Row className='login-label-custom-text'>{label_password}</Row>}
              name='password'
              rules={[{ required: loginAction !== ActionLogin.Username }]}
              hidden={loginAction === ActionLogin.Username}
            >
              <Input.Password iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
            </Form.Item>

            <Form.Item
              className='password-login-form'
              label={<Row className='login-label-custom-text'>{label_repeatPassword}</Row>}
              name='repeat-password'
              dependencies={['password']}
              hidden={loginAction !== ActionLogin.RegisterPassword}
              rules={[
                { required: loginAction === ActionLogin.RegisterPassword },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('The new password that you entered do not match!'))
                  },
                }),
              ]}
            >
              <Input.Password iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType='submit'
                className={'login-form-button' + (loginAction === ActionLogin.Username ? ' d-none' : '')}
              >
                {label_enterText}
              </Button>
            </Form.Item>
            <Form.Item hidden={loginAction !== ActionLogin.InsertPassword}>
              <Button type='link' className='login-forgot-password ' onClick={onForgotPassword}>
                {label_forgotPassword}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row justify='center' align='middle' style={{ minHeight: '32vh' }} className='login-background pb-3'>
        <Col className='login-contact-text'>
          <div>
            Do you want to subscribe?
            <br />
            Contact us at
          </div>
          <div>
            +39 0734.674492
            <br />
            info@atelier.cloud
          </div>
        </Col>
      </Row>
      <Footer isLogin={true} />
    </Content>
  )
}
