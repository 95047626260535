import { createSlice } from '@reduxjs/toolkit'

export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    currentPage: '',
  },
  reducers: {
    setAdminCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
  },
})

export const { setAdminCurrentPage } = adminSlice.actions
export default adminSlice.reducer
