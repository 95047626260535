export interface BindingElement {
  id: number
  name: string
}

export enum BindingType {
  Sector,
  Group,
  Brand,
  PreferableLanguage,
  MandatoryLanguage,
  Target,
  Market,
  Technology,
  PlatformType,
  ShippingDocument,
  Seasonality,
}
