import { Row, Col, Button, notification } from 'antd'
import { Content } from 'antd/es/layout/layout'
import Logosvg from '../../Svg/Logo'
import {
  label_backToLogin,
  label_forgotPassword,
  label_RecoverPassword,
  label_RecoverPassword_Text,
  label_sentemail,
} from '../../Constants/Labels'
import { useNavigateWrapper } from '../../Helpers/NavigateHelper'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { sendEmailToRecoverPassword } from '../../Services/MailService'
import { isValidResult } from '../../Helpers/ResultHelper'
import { setLoginAction, setUsername } from '../../Slices/LoginSlice'
import { ActionLogin } from '../../Interfaces/LoginInterfaces'

export default function ForgotPassword() {
  const { navigateToLogin } = useNavigateWrapper()
  const username: string = useAppSelector(state => state.present.login.username)
  const dispatch = useAppDispatch()

  const recoverPassword = async () => {
    const result = await sendEmailToRecoverPassword(username)
    if (isValidResult(result)) {
      notification.success({
        message: `${label_sentemail}`,
        duration: 5,
      })
      dispatch(setUsername(null))
      dispatch(setLoginAction(ActionLogin.Username))
      navigateToLogin()
    }
  }
  return (
    <Content className='page-content login' id='page-content-login'>
      <Row justify='center' align='middle' style={{ height: '100vh' }}>
        <Col xxl={4} xl={6} lg={8} md={14} sm={16}>
          <div className='pa-5 text-center logo'>
            <Logosvg />
          </div>
          <Col className='forgot-password-title'>
            <div>{label_forgotPassword}</div>
          </Col>
          <Col className='forgot-password-text'>
            <div>{label_RecoverPassword_Text}</div>
          </Col>
          <Button className='login-form-button' onClick={recoverPassword}>
            {label_RecoverPassword}
          </Button>
          <Button type='link' className='login-forgot-password ' onClick={navigateToLogin}>
            {label_backToLogin}
          </Button>
        </Col>
      </Row>
    </Content>
  )
}
