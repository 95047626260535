import { Checkbox, Col, Input, Row, Select } from 'antd'
import { LocalFormItem } from './MarketplaceForm'
import { label_mrkpShippingManaged_items, label_mrkpShipping_items } from '../../../Constants/Labels'
import { MarketplaceData, ShippingManagedBy, ShippingMode } from '../../../Interfaces/MarketplaceInterfaces'
import { enum2Options } from 'antd-utils'
import { ComponentType } from '../../../Constants/SheetFieldInterface'
import { marketplaceShippings } from '../ItemsDefinitionMarketplace'
import { UserType } from '../../../Interfaces/LoginInterfaces'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { editedMarketplaceFields } from '../../../Slices/MarketplaceSlice'
import { handleFieldChange } from '../../../Helpers/SheetHelper'
import { label_No, label_Yes } from '../../../Constants/Labels'
import { BindingElement } from '../../../Interfaces/BindingInterface'
import { useCallback } from 'react'

export default function Shipping() {
  const dispatch = useAppDispatch()
  const userType: UserType = useAppSelector(state => state.present.login.userType)
  const shippingDocuments: BindingElement[] = useAppSelector(state => state.present.main.shippingDocuments)

  const currentMarketplace: MarketplaceData = useAppSelector(state => state.present.marketplace.currentMarketplace)
  const editedFields = useAppSelector(state => state.present.marketplace.editedFields)
  const renderSelectOptions = (items: any, labels: any) =>
    enum2Options(items).map((item: any) => (
      <Select.Option key={item.value} value={item.value}>
        {labels[item.value]}
      </Select.Option>
    ))
  const getShippingDocuments = useCallback(() => {
    return shippingDocuments.map(e => ({ label: e.name, value: e.id }))
  }, [shippingDocuments])

  return (
    <>
      {marketplaceShippings.map((item, index) => {
        const { name, label, componentType, rules, disabled, maxLength } = item
        const isDisabled = disabled || userType !== UserType.Admin
        const isFieldEdited = Object.keys(editedFields).includes(name) ? ' modified' : ''
        const getChange = value =>
          handleFieldChange(value, name, currentMarketplace, editedFields, editedMarketplaceFields, dispatch)
        const currentValue = currentMarketplace[name] === true
        return (
          <Row key={name}>
            <Col span={8} className={'sheet-form-labels pl-4' + (index % 2 === 0 ? ' color-line' : '')}>
              {label}
            </Col>
            <Col span={16} className={'sheet-form-inputs pl-6 pr-6 pt-3' + (index % 2 === 0 ? ' color-line' : '')}>
              <LocalFormItem
                key={name}
                name={name}
                rules={rules ? [{ required: true }] : undefined}
                valuePropName={componentType === ComponentType.Checkbox ? 'checked' : undefined}
              >
                {componentType === ComponentType.Select && name === 'shippingMode' && (
                  <Select
                    className={'sheet-select' + isFieldEdited}
                    disabled={isDisabled}
                    onChange={e => getChange(e.toString())}
                  >
                    {renderSelectOptions(ShippingMode, label_mrkpShipping_items)}
                  </Select>
                )}
                {componentType === ComponentType.Select && name === 'shippingManaged' && (
                  <Select
                    className={'sheet-select' + isFieldEdited}
                    disabled={isDisabled}
                    onChange={e => getChange(e.toString())}
                  >
                    {renderSelectOptions(ShippingManagedBy, label_mrkpShippingManaged_items)}
                  </Select>
                )}
                {componentType === ComponentType.SelectMultiple && name === 'shippingDocumentsId' && (
                  <Select
                    className={'sheet-select-multiple' + isFieldEdited}
                    mode='multiple'
                    allowClear
                    options={getShippingDocuments()}
                    optionFilterProp='label'
                    disabled={isDisabled}
                    onChange={e => getChange(e.toString())}
                    maxTagCount={'responsive'}
                  />
                )}
                {componentType === ComponentType.Input && (
                  <Input
                    className={'sheet-input sheet-text' + isFieldEdited}
                    disabled={isDisabled}
                    onBlur={e => getChange(e.target.value)}
                    maxLength={maxLength}
                  />
                )}
                {componentType === ComponentType.Checkbox &&
                  (userType !== UserType.Seller ? (
                    <Checkbox
                      className={'sheet-form-checkbox' + isFieldEdited}
                      disabled={isDisabled}
                      onChange={e => getChange(e.target.checked)}
                      checked={currentValue}
                    />
                  ) : (
                    <span className='pl-3 checkbox-seller-text'>{currentValue ? label_Yes : label_No}</span>
                  ))}
              </LocalFormItem>
            </Col>
          </Row>
        )
      })}
    </>
  )
}
