function LogoHeader(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='7.5rem' viewBox='0 0 232.878 100.734'>
      <g id='Bianco' transform='translate(-720 -605.045)'>
        <path
          id='Tracciato_575-2'
          data-name='Tracciato 575-2'
          d='M25.522,81.045,86.743,47.226,70.869,13.466,45.728,25.184,34.039,0,0,15.918,25.522,81.045Z'
          transform='matrix(0.966, -0.259, 0.259, 0.966, 749.553, 627.496)'
          fill='#a2ca00'
        />
        <path
          id='Tracciato_596'
          data-name='Tracciato 596'
          d='M23.54-82.94H16.28L0,0H8.25l2.97-17.05h17.6L31.9,0h8.25Zm-11,59.18,4.84-31.79c.88-5.61,1.43-11.33,2.2-16.94h.44c.66,5.61,1.1,11.33,2.09,16.94l5.5,31.79Z'
          transform='translate(720 700)'
          fill='#fff'
        />
        <path
          id='Tracciato_597'
          data-name='Tracciato 597'
          d='M14.3-82.94H6.6V0H32.34V-7.04H14.3Zm37.074-1.1c-17.6,0-20.35,21.56-20.35,42.57,0,19.36,2.2,42.57,20.35,42.57,19.25,0,20.35-27.06,20.35-42.57C71.724-59.73,70.074-84.04,51.374-84.04Zm0,7.48c11.88,0,12.43,20.46,12.43,35.09s-.55,35.09-12.43,35.09-12.43-20.46-12.43-35.09S39.494-77.11,51.374-76.56Zm60.5-6.38h-7.81L93.288-22.55c-.33,1.98-.77,7.48-1.1,11.22h-.44c-.44-3.74-.88-9.13-1.32-11.22L79.538-82.94h-7.92L88.338,0h6.82Z'
          transform='translate(841 700)'
          fill='#fff'
        />
      </g>
    </svg>
  )
}

export default LogoHeader
