import { label_WrongCredentials, label_WrongUsername } from '../Constants/Labels'
import { createRequest, notifyError, isError } from '../Helpers/GenericHelper'
import { InvalidResult, ValidResult } from '../Helpers/ResultHelper'
import {
  LoginRequest,
  LoginResponse,
  FirstLoginRequest,
  UsernameResponse,
  FirstAccessCheck,
} from '../Interfaces/LoginInterfaces'

/**
 * Send a fake token request, always return a valid token
 */
export const requestToken = async (authenticateRequest: LoginRequest) => {
  const url = `/api/login`
  const result = await createRequest<LoginResponse>(url, 'POST', authenticateRequest, '', true, true)
  if (isError(result)) {
    notifyError(label_WrongCredentials, result)
    return InvalidResult<LoginResponse>()
  } else return ValidResult(result.payload)
}

export const checkFirstAccessAction = async (firstAccessRequest: FirstAccessCheck) => {
  const url = `/api/login/checkfirstaccessaction`
  const result = await createRequest<UsernameResponse>(url, 'POST', firstAccessRequest, '', true, true)
  if (isError(result)) {
    notifyError(label_WrongCredentials, result)
    return InvalidResult<UsernameResponse>()
  } else return ValidResult(result.payload)
}

export const firstAccess = async (authenticateRequest: FirstLoginRequest) => {
  const url = `/api/login/firstaccess`
  const result = await createRequest<LoginResponse>(url, 'POST', authenticateRequest, '', true, true)
  if (isError(result)) {
    notifyError(label_WrongCredentials, result)
    return InvalidResult<LoginResponse>()
  } else return ValidResult(result.payload)
}

export const checkUsername = async (authenticateRequest: LoginRequest) => {
  const url = `/api/login/checkusername/`
  const result = await createRequest<UsernameResponse>(url, 'POST', authenticateRequest, '', true, true)
  if (isError(result)) {
    notifyError(label_WrongUsername, result)
    return InvalidResult<UsernameResponse>()
  } else return ValidResult(result.payload)
}

export const registerPassword = async (authenticateRequest: LoginRequest) => {
  const url = `/api/login/registerpassword`
  const result = await createRequest<LoginResponse>(url, 'POST', authenticateRequest, '', true, true)
  if (isError(result)) {
    notifyError(label_WrongCredentials, result)
    return InvalidResult<LoginResponse>()
  } else return ValidResult(result.payload)
}
