import { Col, Image, Row, Upload, notification } from 'antd'
import { label_Upload, label_WrongFileType, label_noFileLoaded } from '../../Constants/Labels'
import { UserType } from '../../Interfaces/LoginInterfaces'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { RcFile } from 'antd/es/upload'
import { GetProp, UploadProps } from 'antd/lib'
import { UploadFileType } from '../../Constants/SheetFieldInterface'
import TrashIcon from '../../Svg/TrashIcon'
import { editedSellerFields } from '../../Slices/SellerSlice'
import { isNotNullOrEmptyUrl } from '../../Helpers/GenericHelper'
import PdfLogo from '../../Svg/PdfLogo'
import { editedMarketplaceFields } from '../../Slices/MarketplaceSlice'

type FileUploaderProps = {
  setFileUrl?: (file: string) => unknown
  fileUrl?: string
  fileType: UploadFileType
  onFileSet: (file: RcFile, type: 'logo' | 'pdf') => unknown
  formUserType: UserType
}

function FileUploader(props: FileUploaderProps) {
  const dispatch = useAppDispatch()
  const userType: UserType = useAppSelector(state => state.present.login.userType)
  type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

  const beforeUpload = (file: FileType) => {
    const isImage = file.type === 'image/jpeg' || file.type === 'image/png'
    const isPdf = file.type === 'application/pdf'
    const isFormatCorrect =
      (props.fileType === UploadFileType.Logo && isImage) || (props.fileType === UploadFileType.Pdf && isPdf)

    if (!isFormatCorrect) {
      notification.error({
        message: label_WrongFileType,
        description: 'JPEG format for images and PDF format for presentations',
        placement: 'topRight',
        duration: 5,
      })
      return
    }
    props.setFileUrl(URL.createObjectURL(file))
    props.onFileSet(file, props.fileType as 'logo' | 'pdf')

    if (props.formUserType === UserType.Seller) {
      dispatch(editedSellerFields({ fieldName: props.fileType, content: file.name }))
    }
    if (props.formUserType === UserType.Marketplace) {
      dispatch(editedMarketplaceFields({ fieldName: props.fileType, content: file.name }))
    }
    return false
  }

  const onRemove = () => {
    props.setFileUrl(null)
    props.onFileSet(null, props.fileType as 'logo' | 'pdf')
    if (userType === UserType.Seller) {
      dispatch(editedSellerFields({ fieldName: props.fileType, content: '' }))
    }
    if (userType === UserType.Marketplace || userType === UserType.Admin) {
      dispatch(editedMarketplaceFields({ fieldName: props.fileType, content: '' }))
    }
  }

  const generateComponent = () => {
    switch (props.fileType) {
      case UploadFileType.Pdf:
        return (
          <a href={props.fileUrl} target='_blank' rel='noopener noreferrer'>
            <PdfLogo />
          </a>
        )
      case UploadFileType.Logo:
        return <Image src={props.fileUrl} alt='logo' preview={false} height={'2.4rem'} width={'auto'} />
      default:
        return
    }
  }

  return (
    <Row justify={'space-between'}>
      <Col>
        {isNotNullOrEmptyUrl(props.fileUrl) ? (
          generateComponent()
        ) : (
          <span className='sheet-text pl-3'>{label_noFileLoaded}</span>
        )}
      </Col>
      {(userType === props.formUserType || userType == UserType.Admin) && (
        <Col className='cursor-pointer'>
          {props.fileUrl && (
            <span onClick={onRemove} className='sheet-trash-icon'>
              <TrashIcon />
            </span>
          )}
          <Upload multiple={false} beforeUpload={beforeUpload}>
            {!props.fileUrl && <span className='sheet-text sheet-upload-button pa-2'>{label_Upload}</span>}
          </Upload>
        </Col>
      )}
    </Row>
  )
}

export default FileUploader
