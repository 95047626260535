import { Layout } from 'antd'
import { Outlet } from 'react-router-dom'
import Header from '../Components/Header'
import { useEffect } from 'react'
import { getBindingData } from '../Services/BindingService'
import { isValidResult } from '../Helpers/ResultHelper'
import { BindingType } from '../Interfaces/BindingInterface'
import {
  setGroups,
  setLanguages,
  setMarkets,
  setPlatformTypes,
  setSeasonalities,
  setSectors,
  setShippingDocuments,
  setTargets,
  setTechnologies,
} from '../Slices/MainSlice'
import { useAppDispatch } from '../hooks'
import { getTokenFromSessionStorage } from '../Helpers/TokenHelper'
import Footer from '../Components/Footer'
import { setUser } from '../Slices/LoginSlice'
import { getUserInfoFromSessionStorage } from '../Helpers/LoginHelper'

export default function Home() {
  const dispatch = useAppDispatch()
  const token = getTokenFromSessionStorage()

  useEffect(() => {
    getBindingData(BindingType.Sector).then(sectors => {
      getBindingData(BindingType.Group).then(groups => {
        getBindingData(BindingType.Target).then(targets => {
          getBindingData(BindingType.Market).then(markets => {
            getBindingData(BindingType.MandatoryLanguage).then(languages => {
              getBindingData(BindingType.Technology).then(technologies => {
                getBindingData(BindingType.PlatformType).then(platformTypes => {
                  getBindingData(BindingType.ShippingDocument).then(shippingDocuments => {
                    getBindingData(BindingType.Seasonality).then(seasonalities => {
                      if (isValidResult(sectors)) dispatch(setSectors(sectors.payload))
                      if (isValidResult(groups)) dispatch(setGroups(groups.payload))
                      if (isValidResult(targets)) dispatch(setTargets(targets.payload))
                      if (isValidResult(markets)) dispatch(setMarkets(markets.payload))
                      if (isValidResult(languages)) dispatch(setLanguages(languages.payload))
                      if (isValidResult(technologies)) dispatch(setTechnologies(technologies.payload))
                      if (isValidResult(platformTypes)) dispatch(setPlatformTypes(platformTypes.payload))
                      if (isValidResult(shippingDocuments)) dispatch(setShippingDocuments(shippingDocuments.payload))
                      if (isValidResult(seasonalities)) dispatch(setSeasonalities(seasonalities.payload))
                    })
                  })
                })
              })
            })
          })
        })
      })
    })
  }, [dispatch, token])

  useEffect(() => {
    dispatch(setUser(getUserInfoFromSessionStorage()))
  }, [dispatch])

  return (
    <Layout className='page-layout' style={{ minHeight: '100vh', maxHeight: '100%' }}>
      <Header />
      <Layout>
        <Outlet />
      </Layout>
      <Footer />
    </Layout>
  )
}
