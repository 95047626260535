import { createRequest, isError, notifyError } from '../Helpers/GenericHelper'
import { InvalidResult, ValidResult } from '../Helpers/ResultHelper'
import { getTokenFromSessionStorage } from '../Helpers/TokenHelper'
import { MailContactRequest, MailToRecoverPasswordRequest } from '../Interfaces/MailInterface'

export const sendEmailToSeller = async (marketplaceId: number, sellerId: number, text: string) => {
  const url = `/api/mail/sendtoseller`
  const token = getTokenFromSessionStorage()

  const body: MailContactRequest = {
    marketplaceId: marketplaceId,
    sellerId: sellerId,
    body: text,
  }

  const result = await createRequest(url, 'POST', body, token, true, true)
  if (isError(result)) {
    notifyError('sendEmailToSeller', result)
    return InvalidResult()
  } else return ValidResult(result.payload)
}

export const sendEmailToMarketplace = async (marketplaceId: number, sellerId: number, text: string) => {
  const url = `/api/mail/sendtomarketplace`
  const token = getTokenFromSessionStorage()

  const body: MailContactRequest = {
    marketplaceId: marketplaceId,
    sellerId: sellerId,
    body: text,
  }

  const result = await createRequest(url, 'POST', body, token, true, true)
  if (isError(result)) {
    notifyError('sendEmailToMarketplace', result)
    return InvalidResult()
  } else return ValidResult(result.payload)
}

export const sendEmailToRecoverPassword = async (email: string) => {
  const url = `/api/mail/sendrecoverpassword`
  const token = getTokenFromSessionStorage()

  const body: MailToRecoverPasswordRequest = {
    email: email,
  }
  const result = await createRequest(url, 'POST', body, token, true, true)
  if (isError(result)) {
    notifyError('sendEmailToRecoverPassword', result)
    return InvalidResult()
  } else return ValidResult(result.payload)
}

export const sendMarketplaceInvitations = async () => {
  const url = `/api/mail/sendMarketplaceInvitations`
  const token = getTokenFromSessionStorage()

  const result = await createRequest(url, 'POST', null, token, true, true)
  if (isError(result)) {
    notifyError('sendMarketplaceInvitations', result)
    return InvalidResult()
  } else return ValidResult(result.payload)
}
