import { RouteObject } from 'react-router-dom'
import Dashboard from '../Pages/Admin/Dashboard'
import Home from '../Pages/Home'
import Login from '../Pages/Login/Login'
import Marketplace from '../Pages/Marketplace/Marketplaces'
import MarketplaceSheet from '../Pages/Marketplace/MarketplaceSheet'
import PrivatePage from '../Pages/PrivatePage'
import SellerSheet from '../Pages/Seller/SellerSheet'
import Sellers from '../Pages/Seller/Sellers'
import FirstAccess from '../Pages/Login/FirstAccess'
import ForgotPassword from '../Pages/Login/ForgotPassword'
import { isProdSite } from '../Helpers/GenericHelper'
import Privacy from '../Pages/Privacy'

export const CLIENT_PAGES_PATH = {
  FirstAccess: '/fa/:authData',
  Login: '/login',
  ForgotPassword: '/forgotPassword',
  Homepage: '/',
  Marketplaces: `/marketplaces`,
  MarketplaceSheet: `/marketplaces/sheet/:id`,
  Sellers: `/sellers`,
  SellerSheet: `/sellers/sheet/:id`,
  Admin: `/admin`,
  Privacy: `/privacy`,
}

export const CLIENT_ROUTES: RouteObject[] = [
  {
    path: CLIENT_PAGES_PATH.Privacy,
    element: <Privacy />,
  },
  {
    path: CLIENT_PAGES_PATH.Login,
    element: <Login />,
  },
  {
    path: CLIENT_PAGES_PATH.ForgotPassword,
    element: <ForgotPassword />,
  },
  {
    path: CLIENT_PAGES_PATH.FirstAccess,
    element: <FirstAccess />,
  },
  {
    path: CLIENT_PAGES_PATH.Homepage,
    element: (
      <PrivatePage>
        <Home />
      </PrivatePage>
    ),
    children: [
      {
        index: true,
        path: CLIENT_PAGES_PATH.Marketplaces,
        element: (
          <PrivatePage>
            <Marketplace />
          </PrivatePage>
        ),
      },
      {
        path: CLIENT_PAGES_PATH.MarketplaceSheet,
        element: (
          <PrivatePage>
            <MarketplaceSheet />
          </PrivatePage>
        ),
      },
      {
        path: CLIENT_PAGES_PATH.Sellers,
        element: (
          <PrivatePage>
            <Sellers />
          </PrivatePage>
        ),
      },
      {
        path: CLIENT_PAGES_PATH.SellerSheet,
        element: (
          <PrivatePage>
            <SellerSheet />
          </PrivatePage>
        ),
      },
      {
        path: CLIENT_PAGES_PATH.Admin,
        element: (
          <PrivatePage>
            <Dashboard />
          </PrivatePage>
        ),
      },
    ],
  },
]

export const MENUKEYS = {
  Marketplaces: 'MARKETPLACES',
  MarketplaceSheet: 'MARKETPLACESHEET',
  Sellers: 'SELLERS',
  SellerSheet: 'SELLERSHEET',
  Dashboard: 'ADMIN',
  Logout: 'LOGOUT',
  MainTitle: 'TITLE',
}

export const menuPerUserType = [
  [MENUKEYS.MarketplaceSheet, isProdSite() ? null : MENUKEYS.Sellers, MENUKEYS.Logout, MENUKEYS.MainTitle],
  [MENUKEYS.SellerSheet, MENUKEYS.Marketplaces, MENUKEYS.Logout, MENUKEYS.MainTitle],
  [MENUKEYS.Marketplaces, MENUKEYS.Sellers, MENUKEYS.Dashboard, MENUKEYS.Logout, MENUKEYS.MainTitle],
]
