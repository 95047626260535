import { Navigate } from 'react-router'
import { CLIENT_PAGES_PATH } from '../Constants/ClientRoutes'
import { getTokenFromSessionStorage } from '../Helpers/TokenHelper'

const PrivatePage = ({ children }) => {
  const token = getTokenFromSessionStorage()
  if (!token) {
    return <Navigate to={CLIENT_PAGES_PATH.Login} />
  }
  return children
}

export default PrivatePage
