import { MarketplaceData } from '../../Interfaces/MarketplaceInterfaces'
import { SheetField, ComponentType } from '../../Constants/SheetFieldInterface'
import { UserType } from '../../Interfaces/LoginInterfaces'
import {
  label_Note,
  label_mrkpAccountingEmails,
  label_mrkpBillingFrequency,
  label_mrkpBrands,
  label_mrkpCharged,
  label_mrkpCommission,
  label_mrkpContact,
  label_mrkpContactEmail,
  label_mrkpDeposit,
  label_mrkpDiscount,
  label_mrkpFixedfeeForItem,
  label_mrkpFixedfeeForMonth,
  label_mrkpGenericEmail,
  label_mrkpGroupageAddress,
  label_mrkpGroups,
  label_mrkpId,
  label_mrkpKpi,
  label_mrkpLanguagesMandatory,
  label_mrkpLanguagesPreferable,
  label_mrkpLogo,
  label_mrkpMarkets,
  label_mrkpMonthlyExpenses,
  label_mrkpName,
  label_mrkpOfficeItaly,
  label_mrkpOfficeItalyNotes,
  label_mrkpOtherBrands,
  label_mrkpPackaging,
  label_mrkpPayment,
  label_mrkpPdf,
  label_mrkpPenalty,
  label_mrkpPlatformType,
  label_mrkpRegisteredOffice,
  label_mrkpRequirements,
  label_mrkpReturns,
  label_mrkpFaultyReturns,
  label_mrkpSectors,
  label_mrkpShipping,
  label_mrkpShippingDashboard,
  label_mrkpShippingDocument,
  label_mrkpShippingManaged,
  label_mrkpShortStory,
  label_mrkpTarget,
  label_mrkpTargetSegment,
  label_mrkpTechTeamEmails,
  label_mrkpTechnology,
  label_mrkpUrl,
  label_mrkpYear,
  label_mrkpAffiliationLink,
  label_mrkpSeasonality,
  label_mrkpUsername,
  label_mrkpPrivacyConsent,
} from '../../Constants/Labels'

export const mktpUserRestrictedFields: string[] = [
  'contact',
  'contactEmail',
  'accountingEmails',
  'techTeamEmails',
  'genericEmail',
  'affiliationLink',
]

export interface MarketplaceSheetField extends SheetField<MarketplaceData> {
  // to do add checks
}

export const marketplaceCommercialInfo: MarketplaceSheetField[] = [
  { name: 'id', label: label_mrkpId, disabled: true, componentType: ComponentType.Input },
  { name: 'username', label: label_mrkpUsername, disabled: true, componentType: ComponentType.Input },
  { name: 'privacyConsent', label: label_mrkpPrivacyConsent, disabled: true, componentType: ComponentType.Input },
  { name: 'name', label: label_mrkpName, rules: true, componentType: ComponentType.Input, maxLength: 200 },
  { name: 'platformTypesId', label: label_mrkpPlatformType, rules: true, componentType: ComponentType.SelectMultiple },
  { name: 'technologiesId', label: label_mrkpTechnology, rules: true, componentType: ComponentType.SelectMultiple },
  { name: 'url', label: label_mrkpUrl, rules: false, componentType: ComponentType.Input, maxLength: 500, type: 'url' },
  {
    name: 'affiliationLink',
    label: label_mrkpAffiliationLink,
    componentType: ComponentType.Input,
    maxLength: 500,
    type: 'url',
  },
  {
    name: 'year',
    label: label_mrkpYear,
    rules: true,
    componentType: ComponentType.Input,
    type: 'number',
    min: 0,
    max: 9999,
  },
  { name: 'shortStory', label: label_mrkpShortStory, componentType: ComponentType.TextArea, maxLength: 4000 },
  { name: 'marketsId', label: label_mrkpMarkets, componentType: ComponentType.SelectMultiple },
  { name: 'registeredOffice', label: label_mrkpRegisteredOffice, componentType: ComponentType.Input, maxLength: 500 },
  { name: 'italyOffice', label: label_mrkpOfficeItaly, componentType: ComponentType.Checkbox },
  { name: 'italyOfficeNotes', label: label_mrkpOfficeItalyNotes, componentType: ComponentType.Input, maxLength: 500 },
  { name: 'sectorsId', label: label_mrkpSectors, componentType: ComponentType.SelectMultiple },
  { name: 'groupsId', label: label_mrkpGroups, componentType: ComponentType.SelectMultiple },
  { name: 'targetsId', label: label_mrkpTarget, componentType: ComponentType.SelectMultiple },
  { name: 'targetSegment', label: label_mrkpTargetSegment, componentType: ComponentType.Input, maxLength: 100 },
  { name: 'otherBrands', label: label_mrkpOtherBrands, componentType: ComponentType.Input, maxLength: 500 },
  { name: 'brandsId', label: label_mrkpBrands, componentType: ComponentType.SelectAutoComplete },
  { name: 'seasonalitiesId', label: label_mrkpSeasonality, componentType: ComponentType.SelectMultiple },
  { name: 'contact', label: label_mrkpContact, componentType: ComponentType.Input, maxLength: 2000 },
  {
    name: 'contactEmail',
    label: label_mrkpContactEmail,
    componentType: ComponentType.Input,
    type: 'email',
    maxLength: 50,
    rules: true,
  },
  {
    name: 'accountingEmails',
    label: label_mrkpAccountingEmails,
    componentType: ComponentType.Input,
    type: 'email',
    maxLength: 200,
  },
  {
    name: 'techTeamEmails',
    label: label_mrkpTechTeamEmails,
    componentType: ComponentType.Input,
    type: 'email',
    maxLength: 200,
  },
  {
    name: 'genericEmail',
    label: label_mrkpGenericEmail,
    componentType: ComponentType.Input,
    type: 'email',
    maxLength: 50,
  },
  { name: 'requirements', label: label_mrkpRequirements, componentType: ComponentType.Input, maxLength: 500 },
  { name: 'mandatoryLanguagesId', label: label_mrkpLanguagesMandatory, componentType: ComponentType.SelectMultiple },
  { name: 'preferableLanguagesId', label: label_mrkpLanguagesPreferable, componentType: ComponentType.SelectMultiple },
  { name: 'pdf', label: label_mrkpPdf, componentType: ComponentType.Upload },
  { name: 'logo', label: label_mrkpLogo, componentType: ComponentType.Upload },
]

export const marketplaceConditions: MarketplaceSheetField[] = [
  { name: 'hasDiscounts', label: label_mrkpDiscount, componentType: ComponentType.Checkbox },
  { name: 'hasComminsions', label: label_mrkpCommission, componentType: ComponentType.Checkbox },
  { name: 'hasFixedFeeForItem', label: label_mrkpFixedfeeForItem, componentType: ComponentType.Checkbox },
  { name: 'hasFixedFeeForMonth', label: label_mrkpFixedfeeForMonth, componentType: ComponentType.Checkbox },
  { name: 'hasMonthlyExpenses', label: label_mrkpMonthlyExpenses, componentType: ComponentType.Checkbox },
  { name: 'hasPackaging', label: label_mrkpPackaging, componentType: ComponentType.Checkbox },
]

export const marketplaceShippings: MarketplaceSheetField[] = [
  { name: 'shippingMode', label: label_mrkpShipping, rules: true, componentType: ComponentType.Select },
  { name: 'groupageAddress', label: label_mrkpGroupageAddress, componentType: ComponentType.Input, maxLength: 200 },
  { name: 'shippingManaged', label: label_mrkpShippingManaged, rules: true, componentType: ComponentType.Select },
  { name: 'shippingNotes', label: label_Note, componentType: ComponentType.Input, maxLength: 500 },
  {
    name: 'shippingDocumentsId',
    label: label_mrkpShippingDocument,
    rules: true,
    componentType: ComponentType.SelectMultiple,
  },
  { name: 'shippingDocumentNotes', label: label_Note, componentType: ComponentType.Input, maxLength: 500 },
  { name: 'hasDashboard', label: label_mrkpShippingDashboard, componentType: ComponentType.Checkbox },
]

export const marketplaceFields: MarketplaceSheetField[] = [
  { name: 'billingFrequency', label: label_mrkpBillingFrequency, rules: true, componentType: ComponentType.Select },
  { name: 'billingNotes', label: label_Note, componentType: ComponentType.Input, maxLength: 500 },
  { name: 'paymentConditions', label: label_mrkpPayment, componentType: ComponentType.Input, maxLength: 500 },
  { name: 'depositRetailer', label: label_mrkpDeposit, componentType: ComponentType.Select },
  { name: 'depositNotes', label: label_Note, componentType: ComponentType.Input, maxLength: 500 },
  { name: 'kpi', label: label_mrkpKpi, componentType: ComponentType.Select },
  { name: 'kpiNotes', label: label_Note, componentType: ComponentType.Input, maxLength: 500 },
  { name: 'penalty', label: label_mrkpPenalty, componentType: ComponentType.Select },
  { name: 'penaltyNotes', label: label_Note, componentType: ComponentType.Input, maxLength: 500 },
]

export const returnsFields: MarketplaceSheetField[] = [
  { name: 'hasReturns', label: label_mrkpReturns, componentType: ComponentType.Checkbox },
  { name: 'returnsMode', label: label_mrkpCharged, componentType: ComponentType.Select },
  { name: 'hasFaultyReturns', label: label_mrkpFaultyReturns, componentType: ComponentType.Checkbox },
  { name: 'faultyReturnsMode', label: label_mrkpCharged, componentType: ComponentType.Select },
]

export const marketplaceAuthorizedUsers = [UserType.Marketplace, UserType.Admin]
