const key = 'Token'

//https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage

export const setTokenToSessionStorage = (token: string) => {
  sessionStorage.setItem(key, token)
}
export const getTokenFromSessionStorage = (): string => {
  return sessionStorage.getItem(key)
}
export const clearTokenFromSessionStorage = () => {
  sessionStorage.removeItem(key)
}
