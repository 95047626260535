function MarketsIcon(props) {
  let fillColor = 'currentColor'

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='2.4rem' height='2.4rem' viewBox='0 0 24.57 21.841' {...props}>
      <path
        id='shop-svgrepo-com'
        d='M7.993,9.166,9.073,2H3.457L1.111,8.143A2.154,2.154,0,0,0,1,8.825c0,1.507,1.57,2.73,3.509,2.73A3.33,3.33,0,0,0,7.993,9.166Zm5.292,2.389c1.938,0,3.509-1.223,3.509-2.73,0-.056,0-.112-.007-.165L16.093,2H10.477l-.7,6.655c0,.056-.005.112-.005.171C9.776,10.332,11.347,11.555,13.285,11.555Zm6.825,1.428v5.4H6.46V12.991a5.744,5.744,0,0,1-1.951.339,5.627,5.627,0,0,1-.779-.067V21.93a1.915,1.915,0,0,0,1.908,1.911H20.929A1.918,1.918,0,0,0,22.84,21.93V13.264a5.865,5.865,0,0,1-.779.067A5.672,5.672,0,0,1,20.11,12.983Zm5.351-4.84L23.112,2H17.5l1.078,7.156a3.322,3.322,0,0,0,3.485,2.4c1.938,0,3.509-1.223,3.509-2.73A2.192,2.192,0,0,0,25.461,8.143Z'
        transform='translate(-1 -2)'
        fill={fillColor}
      />
    </svg>
  )
}

export default MarketsIcon
