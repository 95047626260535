import { createSlice } from '@reduxjs/toolkit'

export const mainSlice = createSlice({
  name: 'main',
  initialState: {
    sectors: [],
    groups: [],
    targets: [],
    markets: [],
    languages: [],
    technologies: [],
    platformTypes: [],
    shippingDocuments: [],
    seasonalities: [],
  },
  reducers: {
    setSectors: (state, action) => {
      state.sectors = action.payload
    },
    setGroups: (state, action) => {
      state.groups = action.payload
    },
    setTargets: (state, action) => {
      state.targets = action.payload
    },
    setMarkets: (state, action) => {
      state.markets = action.payload
    },
    setLanguages: (state, action) => {
      state.languages = action.payload
    },
    setTechnologies: (state, action) => {
      state.technologies = action.payload
    },
    setPlatformTypes: (state, action) => {
      state.platformTypes = action.payload
    },
    setShippingDocuments: (state, action) => {
      state.shippingDocuments = action.payload
    },
    setSeasonalities: (state, action) => {
      state.seasonalities = action.payload
    },
  },
})

export const {
  setSectors,
  setGroups,
  setTargets,
  setLanguages,
  setMarkets,
  setTechnologies,
  setPlatformTypes,
  setShippingDocuments,
  setSeasonalities,
} = mainSlice.actions
export default mainSlice.reducer
