import { Content } from 'antd/es/layout/layout'
import { Button, Col, Row, Spin } from 'antd'
import { useParams } from 'react-router-dom'
import { EyeOutlined } from '@ant-design/icons'
import { MarketplaceData } from '../../Interfaces/MarketplaceInterfaces'
import { enableMarketplace, getMarketplaceData, saveMarketplaceData } from '../../Services/MarketplaceService'
import { useEffect, useState } from 'react'
import { NEW_ELEMENT_ID } from '../../Constants/Configuration'
import { isValidResult } from '../../Helpers/ResultHelper'
import MarketplaceForm from './Components/MarketplaceForm'
import { UploadFile, deleteFile, uploadFile } from '../../Services/UploadService'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { resetMarketplaceEdit, setMarketplace, setMarketplaceCurrentPage } from '../../Slices/MarketplaceSlice'
import { UserType } from '../../Interfaces/LoginInterfaces'
import { MENUKEYS } from '../../Constants/ClientRoutes'
import { setSeller, setSellerCurrentPage } from '../../Slices/SellerSlice'
import { setAdminCurrentPage } from '../../Slices/AdminSlice'
import PdfLogo from '../../Svg/PdfLogo'
import ContactIcon from '../../Svg/ContactIcon'
import {
  label_mrkpSheet_eyeDescription,
  label_mrkpSheetLeft_contacts,
  label_mrkpSheetLeft_pdf,
} from '../../Constants/Labels'
import MailDialog, { MailData } from '../Components/MailDialog'
import { SellerData } from '../../Interfaces/SellerInterfaces'
import { getSellerData } from '../../Services/SellerService'
import { useNavigateWrapper } from '../../Helpers/NavigateHelper'

export default function MarketplaceSheet() {
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const entityId: number = useAppSelector(state => state.present.login.entityId)
  const [currentMarketplace, setCurrentMarketplace] = useState<MarketplaceData>()
  const [isLoading, setIsLoading] = useState(false)
  const userType: UserType = useAppSelector(state => state.present.login.userType)
  const editedFields = useAppSelector(state => state.present.marketplace.editedFields)
  const [mailData, setMailData] = useState<MailData>(null)
  const currentSeller: SellerData = useAppSelector(state => state.present.seller.selected)

  const onSubmitHandler = async (values: MarketplaceData, logo: File, pdf: File) => {
    values.id = parseInt(id)
    setIsLoading(true)
    const response = await saveMarketplaceData(values)

    if (isValidResult(response)) {
      if (logo) {
        await uploadFile(logo, UploadFile.MarketplaceLogo, values.id)
      } else {
        if (editedFields['logo'] === '') {
          await deleteFile(values.logo, UploadFile.MarketplaceLogo, values.id)
        }
      }
      if (pdf) {
        await uploadFile(pdf, UploadFile.MarketplacePdf, values.id)
      } else {
        if (editedFields['pdf'] === '') {
          await deleteFile(values.pdf, UploadFile.MarketplacePdf, values.id)
        }
      }
    }

    getMarketplaceData(parseInt(id)).then(mrkp => {
      if (isValidResult(mrkp)) {
        dispatch(setMarketplace(mrkp.payload))
        dispatch(resetMarketplaceEdit())
        setCurrentMarketplace(mrkp.payload)
      }
    })
    setIsLoading(false)
  }

  const onEnabledHandler = async (value: boolean) => {
    setIsLoading(true)
    const response = await enableMarketplace(parseInt(id), value)
    if (isValidResult(response)) {
      const mrkp = { ...currentMarketplace, isEnabled: value }
      dispatch(setMarketplace(mrkp))
      setCurrentMarketplace(mrkp)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (parseInt(id) !== NEW_ELEMENT_ID) {
      getMarketplaceData(parseInt(id)).then(mrkp => {
        if (isValidResult(mrkp)) {
          dispatch(setMarketplace(mrkp.payload))
          setCurrentMarketplace(mrkp.payload)
        }
      })
    }
  }, [id, dispatch])

  useEffect(() => {
    switch (userType) {
      case UserType.Marketplace:
        dispatch(setMarketplaceCurrentPage(MENUKEYS.MarketplaceSheet))
        break
      case UserType.Seller:
        dispatch(setSellerCurrentPage(MENUKEYS.Marketplaces))
        break
      case UserType.Admin:
        dispatch(setAdminCurrentPage(MENUKEYS.Marketplaces))
        break
    }
  }, [dispatch, userType])

  useEffect(() => {
    if (userType === UserType.Seller) {
      getSellerData(entityId).then(seller => {
        if (isValidResult(seller)) dispatch(setSeller(seller.payload))
      })
      dispatch(setSellerCurrentPage(MENUKEYS.Marketplaces))
    }
    if (userType === UserType.Admin) {
      dispatch(setAdminCurrentPage(MENUKEYS.Marketplaces))
    }
  }, [entityId, userType, dispatch])

  const handleMailClick = e => {
    e.preventDefault()
    if (currentMarketplace.contactEmail) {
      setMailData({
        isOpen: true,
        marketplaceName: currentMarketplace.name,
        marketplaceId: currentMarketplace.id,
        sellerId: currentSeller.id,
      })
    } else window.location.href = currentMarketplace.affiliationLink
  }

  return (
    <>
      <Content className='page-content marketplace-sheet ml-6' id='page-content-marketplace-sheet'>
        {!currentMarketplace ? (
          <Row justify='center' align='middle' style={{ height: '80vh' }} className='bg-white'>
            <Spin />
          </Row>
        ) : (
          <Row justify='center' align='top' style={{ height: '100%' }} className='bg-white'>
            <Col span={4} className='sheet-text pt-6 position-sticky top-0'>
              <div className='d-flex flex-column align-end sheet-left'>
                {currentMarketplace.logo ? (
                  <img
                    className='mb-6'
                    src={`/repo/marketplace${currentMarketplace.id}/${currentMarketplace.logo}`}
                    style={{ maxHeight: '50px', width: 'auto' }}
                  />
                ) : null}
                <span className='mb-6 brand-name'>{currentMarketplace.name}</span>
                {currentMarketplace.pdf ? (
                  <div className='pdf-info d-flex flex-column align-end mb-6'>
                    <a
                      href={`/repo/marketplace${currentMarketplace.id}/${currentMarketplace.pdf}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <PdfLogo />
                    </a>
                    <span>{label_mrkpSheetLeft_pdf}</span>
                  </div>
                ) : null}
                {userType === UserType.Seller &&
                (currentMarketplace.contactEmail || currentMarketplace.affiliationLink) ? (
                  <>
                    <a
                      onClick={currentMarketplace.contactEmail ? handleMailClick : null}
                      href={currentMarketplace.affiliationLink}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <ContactIcon />
                    </a>
                    <span>{label_mrkpSheetLeft_contacts}</span>
                  </>
                ) : null}
              </div>
            </Col>
            <Col span={14} className='pt-6'>
              {currentMarketplace && (
                <Spin spinning={isLoading}>
                  <MarketplaceForm
                    currentMarketplace={currentMarketplace}
                    onSubmit={onSubmitHandler}
                    onEnable={onEnabledHandler}
                  />
                  {userType !== UserType.Seller && (
                    <div className='d-flex gap-8 pb-6'>
                      <EyeOutlined />
                      <span> {label_mrkpSheet_eyeDescription} </span>
                    </div>
                  )}
                </Spin>
              )}
            </Col>
          </Row>
        )}
      </Content>
      <MailDialog emailTo='marketplace' mailData={mailData} onClose={() => setMailData({ isOpen: false })} />
    </>
  )
}
