import { createSearchParams, generatePath, useNavigate } from 'react-router-dom'
import { CLIENT_PAGES_PATH } from '../Constants/ClientRoutes'

export function useNavigateWrapper() {
  const navigate = useNavigate()

  const navigateToLogin = () => navigate(CLIENT_PAGES_PATH.Login)
  const navigateToHomepage = () => navigate(CLIENT_PAGES_PATH.Homepage)

  const navigateToMarketplaces = () => navigate(CLIENT_PAGES_PATH.Marketplaces)

  const navigateToMarketplaceSheet = (id: number) => {
    navigate(generatePath(CLIENT_PAGES_PATH.MarketplaceSheet, { id: id }))
  }
  const navigateToSellers = () => navigate(CLIENT_PAGES_PATH.Sellers)

  const navigateToSellerSheet = (id: number) => navigate(generatePath(CLIENT_PAGES_PATH.SellerSheet, { id: id }))

  const navigateToAdmin = () => navigate(CLIENT_PAGES_PATH.Admin)

  const navigateToForgotPassword = () => navigate(CLIENT_PAGES_PATH.ForgotPassword)

  const navigateToPrivacy = () => navigate(CLIENT_PAGES_PATH.Privacy)

  return {
    navigate,
    navigateToHomepage,
    navigateToMarketplaces,
    navigateToMarketplaceSheet,
    navigateToSellers,
    navigateToSellerSheet,
    navigateToAdmin,
    navigateToLogin,
    navigateToForgotPassword,
    navigateToPrivacy,
  }
}

const addQueryParam = (url: string, queryParams: string): string => {
  return url + (queryParams ? '?' + queryParams : queryParams)
}

export type NavigationWrapper = ReturnType<typeof useNavigateWrapper>
export const generateQueryParams = (params: { [key: string]: string }): string => {
  return createSearchParams(params).toString()
}
