import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { SellerData } from '../../../Interfaces/SellerInterfaces'

type SellerTableProps = {
  columns: ColumnsType<SellerData>
  sellersData: null | SellerData[]
  isLoading: boolean
}
export default function SellerTable(props: SellerTableProps) {
  return (
    <Table
      rowKey={'id'}
      id='seller-table'
      rowClassName={(record, index) => (index % 2 === 0 ? ' color-line' : ' empty-line')}
      className='pa-6'
      columns={props.columns}
      dataSource={props.sellersData}
      showSorterTooltip={false}
      loading={props.isLoading}
      // scroll={{ y: 'auto' }}
      pagination={{ position: ['bottomRight'] }}
    />
  )
}
