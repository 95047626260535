import { Col, Row } from 'antd'
import { useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks'
import { ActionLogin, UserType } from '../Interfaces/LoginInterfaces'
import { useNavigateWrapper } from '../Helpers/NavigateHelper'
import { MenuItemType } from 'antd/es/menu/hooks/useItems'
import { clearTokenFromSessionStorage } from '../Helpers/TokenHelper'
import { MENUKEYS, menuPerUserType } from '../Constants/ClientRoutes'
import MarketsIcon from '../Svg/MarketIcon'
import LogoHeader from '../Svg/LogoHeader'
import { SellerData } from '../Interfaces/SellerInterfaces'
import LogoutIcon from '../Svg/LogoutIcon'
import MarketplaceIcon from '../Svg/MarketplaceIcon'
import { clearUserInfoFromSessionStorage } from '../Helpers/LoginHelper'
import { resetSellerEdit } from '../Slices/SellerSlice'
import {
  label_headerAdmin,
  label_headerMarketplaceHUBCommunity,
  label_headerMarketplaceSheet,
  label_headerMarketplaces,
  label_headerSellerSheet,
  label_headerSellersPartners,
} from '../Constants/Labels'
import { MarketplaceData } from '../Interfaces/MarketplaceInterfaces'
import { resetMarketplaceEdit } from '../Slices/MarketplaceSlice'
import { setLoginDefault } from '../Slices/LoginSlice'

export default function Header() {
  const dispatch = useAppDispatch()
  const {
    navigateToMarketplaces,
    navigateToSellers,
    navigateToMarketplaceSheet,
    navigateToSellerSheet,
    navigateToLogin,
    navigateToAdmin,
  } = useNavigateWrapper()
  const usertype: UserType = useAppSelector(state => state.present.login.userType)
  const entityid: number = useAppSelector(state => state.present.login.entityId)
  const currentSeller: SellerData = useAppSelector(state => state.present.seller.selected)
  const currentMarketplace: MarketplaceData = useAppSelector(state => state.present.marketplace.currentMarketplace)
  const currentSellerPage: string = useAppSelector(state => state.present.seller.currentPage)
  const currentMarketplacePage: string = useAppSelector(state => state.present.marketplace.currentPage)
  const currentAdminPage: string = useAppSelector(state => state.present.admin.currentPage)

  type ClickMenu = MenuItemType & { onClick: () => unknown }

  const items: ClickMenu[] = useMemo(() => {
    return [
      {
        label: currentSeller?.businessName || label_headerSellerSheet,
        key: MENUKEYS.SellerSheet,
        onClick: () => navigateToSellerSheet(entityid),
      },
      {
        label: currentMarketplace ? currentMarketplace.name : label_headerMarketplaceSheet,
        key: MENUKEYS.MarketplaceSheet,
        onClick: () => navigateToMarketplaceSheet(entityid),
      },
      {
        label: label_headerSellersPartners,
        key: MENUKEYS.Sellers,
        onClick: () => navigateToSellers(),
      },
      {
        label: label_headerMarketplaces,
        key: MENUKEYS.Marketplaces,
        onClick: () => navigateToMarketplaces(),
      },
      {
        label: label_headerAdmin,
        key: MENUKEYS.Dashboard,
        onClick: () => navigateToAdmin(),
      },
      {
        label: label_headerMarketplaceHUBCommunity,
        key: MENUKEYS.MainTitle,
        onClick: null,
      },
      {
        label: (
          <div
            onClick={() => {
              dispatch(setLoginDefault())
              dispatch(resetSellerEdit())
              dispatch(resetMarketplaceEdit())
              clearTokenFromSessionStorage()
              clearUserInfoFromSessionStorage()
              navigateToLogin()
            }}
            className='cursor-pointer'
          >
            <LogoutIcon />
          </div>
        ),
        key: MENUKEYS.Logout,
        onClick: null,
      },
    ].filter(e => menuPerUserType[usertype].includes(e.key))
  }, [
    usertype,
    currentMarketplace,
    currentSeller,
    dispatch,
    entityid,
    navigateToAdmin,
    navigateToLogin,
    navigateToMarketplaceSheet,
    navigateToMarketplaces,
    navigateToSellerSheet,
    navigateToSellers,
  ])

  const getSpanValue = (key, usertype) => {
    switch (key) {
      case MENUKEYS.MainTitle:
        return 12
      case MENUKEYS.SellerSheet:
      case MENUKEYS.MarketplaceSheet:
      case MENUKEYS.Marketplaces:
      case MENUKEYS.Sellers:
      case MENUKEYS.Dashboard:
        return usertype === 2 ? 2 : 3
      case MENUKEYS.Logout:
        return 3
      default:
        return 3
    }
  }

  const renderIcon = key => {
    switch (key) {
      case MENUKEYS.SellerSheet:
        return (
          <span className='market-icon-header mr-2'>
            <MarketsIcon />
          </span>
        )
      case MENUKEYS.MarketplaceSheet:
        return (
          <span className='market-icon-header mr-2'>
            <MarketplaceIcon />
          </span>
        )
      default:
        return null
    }
  }

  const checkCurrentPage = (value: string) => {
    switch (usertype) {
      case UserType.Seller:
        return value === currentSellerPage ? ' active-page' : ''
      case UserType.Marketplace:
        return value === currentMarketplacePage ? ' active-page' : ''
      case UserType.Admin:
        return value === currentAdminPage ? ' active-page' : ''
      default:
        return ''
    }
  }

  return (
    <Row className='navbar-container'>
      {items.map(item => {
        const excludedCursorClasses = [MENUKEYS.MainTitle, MENUKEYS.Logout]
        const spanValue = getSpanValue(item.key, usertype)
        const className = 'ant-menu-title-content'
        const cursorClass = excludedCursorClasses.includes(item.key as string) ? '' : 'cursor-pointer'
        const activePage = checkCurrentPage(item.key.toString())
        return (
          <Col
            className={`${className} item-${item.key} ${cursorClass} ${activePage}`}
            key={item.key}
            span={spanValue}
            onClick={item.onClick}
          >
            {renderIcon(item.key)}
            <span>{item.label}</span>
          </Col>
        )
      })}
      <Col span={3}>
        <LogoHeader />
      </Col>
    </Row>
  )
}
