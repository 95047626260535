import { Col, Input, Row, Select } from 'antd'
import { LocalFormItem } from './MarketplaceForm'
import { label_mrkpBillingFrequency_items, label_mrkpThreeState_items } from '../../../Constants/Labels'
import { BillingFrequency, MarketplaceData, ThreeState } from '../../../Interfaces/MarketplaceInterfaces'
import { enum2Options } from 'antd-utils'
import { ComponentType } from '../../../Constants/SheetFieldInterface'
import { marketplaceFields } from '../ItemsDefinitionMarketplace'
import { UserType } from '../../../Interfaces/LoginInterfaces'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { editedMarketplaceFields } from '../../../Slices/MarketplaceSlice'
import { handleFieldChange } from '../../../Helpers/SheetHelper'

export default function Invoicing() {
  const dispatch = useAppDispatch()
  const userType: UserType = useAppSelector(state => state.present.login.userType)
  const currentMarketplace: MarketplaceData = useAppSelector(state => state.present.marketplace.currentMarketplace)
  const editedFields = useAppSelector(state => state.present.marketplace.editedFields)

  const renderSelectOptions = (items: any, labels: any) =>
    enum2Options(items).map((item: any) => (
      <Select.Option key={item.value} value={item.value}>
        {labels[item.value]}
      </Select.Option>
    ))

  return (
    <>
      {marketplaceFields.map((item, index) => {
        const { name, label, componentType, disabled, rules, maxLength } = item
        const isDisabled = disabled || userType !== UserType.Admin
        const isFieldEdited = Object.keys(editedFields).includes(item.name) ? ' modified' : ''
        const getChange = value =>
          handleFieldChange(value, name, currentMarketplace, editedFields, editedMarketplaceFields, dispatch)

        return (
          <Row>
            <Col span={8} className={'sheet-form-labels pl-4' + (index % 2 === 0 ? ' color-line' : '')}>
              {label}
            </Col>
            <Col span={16} className={'sheet-form-inputs pl-6 pr-6 pt-3' + (index % 2 === 0 ? ' color-line' : '')}>
              <LocalFormItem
                key={name}
                name={name}
                rules={rules ? [{ required: true }] : undefined}
                valuePropName={componentType === ComponentType.Checkbox ? 'checked' : undefined}
              >
                {componentType === ComponentType.Select && name === 'billingFrequency' && (
                  <Select
                    className={'sheet-select' + isFieldEdited}
                    disabled={isDisabled}
                    onChange={e => getChange(e.toString())}
                  >
                    {renderSelectOptions(BillingFrequency, label_mrkpBillingFrequency_items)}
                  </Select>
                )}
                {componentType === ComponentType.Select && name !== 'billingFrequency' && (
                  <Select
                    className={'sheet-select' + isFieldEdited}
                    disabled={isDisabled}
                    onChange={e => getChange(e.toString())}
                  >
                    {renderSelectOptions(ThreeState, label_mrkpThreeState_items)}
                  </Select>
                )}
                {componentType === ComponentType.Input && (
                  <Input
                    className={'sheet-input sheet-text' + isFieldEdited}
                    disabled={isDisabled}
                    onBlur={e => getChange(e.target.value)}
                    maxLength={maxLength}
                  />
                )}
              </LocalFormItem>
            </Col>
          </Row>
        )
      })}
    </>
  )
}
