export interface SheetField<T> {
  name: keyof T
  label: string
  disabled?: boolean
  rules?: boolean
  componentType: ComponentType
  type?: string
  maxLength?: number
  placeholder?: string
  min?: number
  max?: number
}

export enum ComponentType {
  Input = 'Input',
  InputNumber = 'InputNumber',
  TextArea = 'TextArea',
  Select = 'Select',
  SelectMultiple = 'SelectMultiple',
  SelectAutoComplete = 'SelectAutoComplete',
  Checkbox = 'Checkbox',
  Upload = 'Upload',
}

export enum UploadFileType {
  Pdf = 'pdf',
  Logo = 'logo',
}
