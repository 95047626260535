export interface LoginRequest {
  username: string
  password: string
}

export interface FirstLoginRequest {
  authenticationData: string
  password: string
}
export interface FirstAccessCheck {
  authenticationData: string
}

export interface LoginResponse {
  username: string
  id: string
  type: UserType
  token: string
  entityId: number //Seller || Marketplace
  privacyConsent: boolean
}

export interface UserInfo {
  userId: number
  userType: UserType
  username: string
  entityId: number
  privacyConsent: boolean
  //entityHubId: number
}

export enum UserType {
  Marketplace,
  Seller,
  Admin,
}

export enum ActionLogin {
  Username,
  RegisterPassword,
  InsertPassword,
  FirstAccess,
  Privacy,
}

export interface UsernameResponse {
  actionType: ActionLogin
  username?: string
}
