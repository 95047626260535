function ArrowLogin(props) {
  return (
    <>
      <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 24 24'>
        <path fill='#062341' d='M8 5v14l11-7z' />
      </svg>
    </>
  )
}

export default ArrowLogin
