export const label_save = 'Save'
export const label_Note = 'Notes'
export const label_Other = 'Other'
export const label_Yes = 'Yes'
export const label_No = 'No'
export const label_Upload = 'Upload'
export const label_Config = 'Config_Info'
export const label_noFileLoaded = 'No file uploaded'

//*********************ERRORS****************/
export const label_WrongUsername = 'Check Username'
export const label_WrongCredentials = 'Check Credentials'
export const label_WrongFileType = 'Wrong file type'
export const label_ResetChanges = 'Reset changes'
export const label_msgResetChanges = 'No changes to be reset'

//*****************POPCONFIRM ************************/
export const label_popconfirmChanges = 'Are you sure to reset your changes?'

//**********************HEADER LABELS****************************/
export const label_headerSellersPartners = 'Sellers & Partners'
export const label_headerMarketplaces = 'Marketplaces'
export const label_headerAdmin = 'Admin'
export const label_headerMarketplaceHUBCommunity = 'Lifestyle Omnichannel Village'
export const label_headerSellerSheet = 'Seller Sheet'
export const label_headerMarketplaceSheet = 'Marketplace Sheet'

//*********************LOGIN****************/
export const label_emailLogin = 'Email'
export const label_password = 'Password'
export const label_repeatPassword = 'Repeat password'
export const label_enterText = 'Enter'
export const label_forgotPassword = 'Forgot your password?'
export const label_RecoverPassword = 'Recover password'
export const label_RecoverPassword_Text =
  'Click on the button below to receive an email and follow the instructions provided to recover your password'
export const label_backToLogin = 'Back to login'
export const label_sentemail = 'Email has been sent successfully.'

//**********************MARKETPLACE****************************/
export const label_mrkpAdd = 'Add Marketplace'
export const label_mrkpId = 'Id'
export const label_mrkpUsername = 'Username'
export const label_mrkpPrivacyConsent = 'Privacy consent'
export const label_mrkpCommercialInfo = 'Commercial Info'
export const label_mrkpConditions = 'Conditions - flag managed conditions'
export const label_mrkpConditionsRetailer = 'Conditions'
export const label_mrkpMyStore = 'My Activations'
export const label_mrkpShippingMethods = 'Shipping methods'
export const label_mrkpInvoicing = 'Invoicing'
export const label_mrkpReturnsHandling = 'Returns handling'
export const label_mrkpFilter_options = ['Yes', 'No']
export const label_mrkpSheetLeft_pdf = 'Presentation'
export const label_mrkpSheetLeft_contacts = 'Contact Mp'
export const label_mrkpSheet_eyeDescription = 'To be filled in for Atelier internal use, not visible to Partners'

//COMMERCIAL INFO
export const label_mrkpName = 'Marketplace name'
export const label_mrkpPlatformType = 'Type of platform'
//export const label_mrkpPlatformType_items = ['B2C open', 'B2C private', 'B2B open', 'B2B private']
export const label_mrkpTechnology = 'Technology'
export const label_mrkpTecnlogy_items = ['website', 'app']
export const label_mrkpUrl = 'Link to platform'
export const label_mrkpAffiliationLink = 'Link to affiliate'
export const label_mrkpYear = 'Start-up Year'
export const label_mrkpShortStory = 'Description'
export const label_mrkpMarkets = 'Markets served'
export const label_mrkpRegisteredOffice = 'Company name and headquarters'
export const label_mrkpOfficeItaly = 'Italian headquarters or branches'
export const label_mrkpOfficeItalyNotes = 'Address Italia headquarter'
export const label_mrkpSectors = 'Sectors (gender)'
export const label_mrkpGroups = 'Categories'
export const label_mrkpOtherBrands = 'Brand mix or preferrable'
export const label_mrkpBrands = 'Brands List managed in HUB'
export const label_mrkpContact = 'Sales rapresentative ( Name - Email - Tel)'
export const label_mrkpContactEmail = 'Sales Team Email'
export const label_mrkpAccountingEmails = 'Accounting Emails'
export const label_mrkpTechTeamEmails = 'Tech Team Emails'
export const label_mrkpGenericEmail = 'Generic email'
export const label_mrkpTarget = 'Target'
export const label_mrkpTargetSegment = 'Target segment'
export const label_mrkpSeasonality = 'Seasonality'
export const label_mrkpRequirements = 'Requirements for Sellers'
export const label_mrkpLanguages = 'Content languages'
export const label_mrkpLanguagesMandatory = 'Mandatory languages'
export const label_mrkpLanguagesPreferable = 'Preferable languages'
export const label_mrkpPdf = 'Pdf presentation'
export const label_mrkpLogo = 'Company Logo'
export const label_mrkpDateAct = 'From date'
export const label_mrkpActivation = 'Activated'
export const label_mrkpVisibility = 'Visibility'
export const label_italyOfficeNotes = 'italyOfficeNotes'
export const label_logo = 'logo'

//CONDITIONS
export const label_mrkpDiscount = 'Discount'
export const label_mrkpCommission = 'Commission'
export const label_mrkpFixedfeeForItem = 'Fixed Fee per Item/transaction'
export const label_mrkpFixedfeeForMonth = 'Fixed Fee per month'
export const label_mrkpMonthlyExpenses = 'Monthly expenses (custom, logistics…)'
export const label_mrkpPackaging = 'Packaging (mp branded)'
//SHIPPING
export const label_mrkpShipping = 'Shipping'
export const label_mrkpShipping_items = ['groupage', 'final address']
export const label_mrkpGroupageAddress = 'Groupage address'
export const label_mrkpShippingManaged = 'Shipping managed by'
export const label_mrkpShippingManaged_items = ['seller', 'marketplace', 'other']

export const label_mrkpShippingDocument = 'Shipping document'
//export const label_mrkpShippingDocument_items = ['packing list', 'airway bill', 'exportation document', 'invoice']
export const label_mrkpShippingDashboard = 'Dashboard to fill in'
export const label_mrkpLOthers = 'Others'
//INVOICING
export const label_mrkpBillingFrequency = 'Billing frequency'
export const label_mrkpBillingFrequency_items = ['by order', 'daily', 'weekly', 'monthly', 'other']
export const label_mrkpPayment = 'Payment conditions'
export const label_mrkpDeposit = 'Deposit to retailer'
export const label_mrkpKpi = 'Target KPI'
export const label_mrkpPenalty = 'Penalty'
export const label_mrkpThreeState_items = ['yes', 'no', 'other']

//RETURNS
export const label_mrkpReturns = 'Returns'
export const label_mrkpReturns_items = ['marketplace', 'seller']
export const label_mrkpFaultyReturns = 'Faulty returns'
export const label_mrkpCharged = 'Charged to'

//LIST
export const label_mrkpLNameList = 'Name'
export const label_mrkpLEnabled = 'Enabled'
export const label_mrkpLName = 'Marketplace'
export const label_mrkpLType = 'Business Model'
export const label_mrkpLStart = 'Start date'
export const label_mrkpLDescription = 'Description'
export const label_mrkpLMarket = 'Market'
export const label_mrkpLSector = 'Sector'
export const label_mrkpLCategory = 'Category'
export const label_mrkpLSeasonality = 'Seasonality'
export const label_mrkpLTarget = 'Target'
export const label_mrkpLBrands = 'Brand'
export const label_mrkpLCommercialInfo = 'Commercial Info'
export const label_mrkpLConditions = 'Conditions'
export const label_mrkpLShipping = 'Shipping'
export const label_mrkpLDiscount = 'Discount'
export const label_mrkpLCommission = 'Commission'
export const label_mrkpLFixedfeeForItem = 'Feexitem'
export const label_mrkpLFixedfeeForMonth = 'Feexmonth'
export const label_mrkpLMonthlyExpenses = 'ExpMonth'
export const label_mrkpLPackaging = 'Packaging'

export const label_mrkpEnabled = 'Enabled'
export const label_mrkpDisabled = 'Disabled'

//Activations
export const label_mrkpSendEmail = 'Contact'
export const label_mrkpSentEmail = ' contact requests '
export const label_mrkpSendFirstEmail = 'First contact request'
export const label_mrkpEmailTitle = 'Contact request to '

//**********************SELLER****************************/
export const label_sellerId = 'Id'
export const label_sellerName = 'Company Name'
export const label_sellerPrincipalCity = 'Headquarter (city)'
export const label_sellerBrand = 'Company Brand Name'
export const label_sellerUrl = 'Site link'
export const label_sellerShortStory = 'Short story'
export const label_sellerNShops = 'N° Stores'
export const label_sellerSectors = 'Sectors'
export const label_sellerGroups = 'Categories'
export const label_sellerBrands = 'Brands'
export const label_sellerContact = 'Commercial rapresentative ( Name - Email - Tel)'
export const label_sellerCommercialContact = 'Commercial team Email'
export const label_sellerPdf = 'Pdf presentation'
export const label_sellerViewPresentation = 'View presentation'
export const label_sellerDeletePopconfirm = 'Are you sure to remove your presentation?'
export const label_sellerPlaceholder = 'http://www.example.com'
export const label_sellerLEnabled = 'Enabled'
export const label_sellerEnabled = 'Enabled'
export const label_sellerDisabled = 'Disabled'
export const label_sellerContacts = 'Contact Seller'
export const label_seller_eyeDescription = 'To be filled in for Atelier internal use, not visible to Platforms'

//**********************MAIL DIALOG****************************/
export const label_mailDialogSend = 'Send Email'
export const label_mailDialogCancel = 'Cancel'
export const label_mailDialogAffiliation = 'To join us follow this link'
export const label_mailDialogFromSeller =
  'I would like to get in touch with you to evaluate a possible collaboration with your platform.'
export const label_mailDialogFromMarketplace =
  'I would like to get in touch with you to evaluate a possible collaboration with your company.'
export const label_mailDialogMail = 'Please contact me at'

//**********************OPTIONS****************************/
export const label_technologiesId = 'technologiesId'
export const label_sectorsId = 'sectorsId'
export const label_groupsId = 'groupsId'
export const label_preferableLanguagesId = 'preferableLanguagesId'
export const label_mandatoryLanguagesId = 'mandatoryLanguagesId'
export const label_brandsId = 'brandsId'
export const label_targetsId = 'targetsId'
export const label_marketsId = 'marketsId'
export const label_platformTypesId = 'platformTypesId'
export const label_shippingDocumentsId = 'shippingDocumentsId'
export const label_seasonalitiesId = 'seasonalitiesId'

//**********************ADMIN****************************/
export const label_AlignBrands = 'Align Brands'
export const label_SendMarketplaceInvitation = 'Send Marketplaces Invitations'
export const label_OperationCompleted = 'Operation completed'
