import { Checkbox, Col, Row } from 'antd'
import { LocalFormItem } from './MarketplaceForm'
import { marketplaceConditions } from '../ItemsDefinitionMarketplace'
import { UserType } from '../../../Interfaces/LoginInterfaces'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { editedMarketplaceFields } from '../../../Slices/MarketplaceSlice'
import { MarketplaceData } from '../../../Interfaces/MarketplaceInterfaces'
import { handleFieldChange } from '../../../Helpers/SheetHelper'
import { label_No, label_Yes } from '../../../Constants/Labels'

export default function Conditions() {
  const dispatch = useAppDispatch()
  const userType: UserType = useAppSelector(state => state.present.login.userType)
  const currentMarketplace: MarketplaceData = useAppSelector(state => state.present.marketplace.currentMarketplace)
  const editedFields = useAppSelector(state => state.present.marketplace.editedFields)
  return (
    <>
      {marketplaceConditions.map((item, index) => {
        const { name, disabled, label } = item
        const isDisabled = disabled || userType !== UserType.Admin
        const isFieldEdited = Object.keys(editedFields).includes(name) ? ' modified' : ''
        const getChange = value =>
          handleFieldChange(value, name, currentMarketplace, editedFields, editedMarketplaceFields, dispatch)
        const currentValue = currentMarketplace[name] === true

        return (
          <Row key={name}>
            <Col span={8} className={'sheet-form-labels pl-4' + (index % 2 === 0 ? ' color-line' : '')}>
              {label}
            </Col>
            <Col span={16} className={'sheet-form-inputs pl-6 pr-6 pt-3' + (index % 2 === 0 ? ' color-line' : '')}>
              {userType === UserType.Seller ? (
                <span className='pl-3 checkbox-seller-text'>{currentValue ? label_Yes : label_No}</span>
              ) : (
                <LocalFormItem name={name} valuePropName='checked'>
                  <Checkbox
                    className={'sheet-form-checkbox' + isFieldEdited}
                    disabled={isDisabled}
                    checked={currentValue}
                    onChange={e => getChange(e.target.checked)}
                  />
                </LocalFormItem>
              )}
            </Col>
          </Row>
        )
      })}
    </>
  )
}
