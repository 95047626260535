import { MarketplaceData } from '../Interfaces/MarketplaceInterfaces'
import { SellerData } from '../Interfaces/SellerInterfaces'
import { isNotNullOrEmpty } from './GenericHelper'

export const originalFile = (folder: string, id: number, file: string) => {
  return isNotNullOrEmpty(file) ? `/repo/${folder}${id}/${file}` : null
}

export const handleFieldChange = (
  content: string,
  fieldName: string,
  currentData: MarketplaceData | SellerData,
  editedFields: Object,
  editFieldsReducer: any,
  dispatch: any,
) => {
  const currentField = currentData[fieldName].toString()
  const editedField = editedFields[fieldName]
  if (
    (content !== currentField && content !== editedField) ||
    (content === currentField && Object.keys(editedFields).includes(fieldName))
  ) {
    dispatch(editFieldsReducer({ fieldName, content }))
  }
}
