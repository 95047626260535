import { SellerData } from '../../Interfaces/SellerInterfaces'
import { SheetField, ComponentType } from '../../Constants/SheetFieldInterface'
import { UserType } from '../../Interfaces/LoginInterfaces'
import {
  label_mrkpId,
  label_sellerBrand,
  label_sellerBrands,
  label_sellerContact,
  label_sellerGroups,
  label_sellerNShops,
  label_sellerName,
  label_sellerPdf,
  label_sellerPrincipalCity,
  label_sellerSectors,
  label_sellerShortStory,
  label_sellerUrl,
  label_sellerPlaceholder,
  label_sellerCommercialContact,
  label_mrkpUsername,
  label_mrkpPrivacyConsent,
} from '../../Constants/Labels'

export interface SellerSheetField extends SheetField<SellerData> {
  // to do add checks
}

export const sellerUserRestrictedFields: string[] = ['contact', 'email']

export const sellerFormItems: SellerSheetField[] = [
  { name: 'id', label: label_mrkpId, disabled: true, componentType: ComponentType.Input },
  { name: 'username', label: label_mrkpUsername, disabled: true, componentType: ComponentType.Input },
  { name: 'privacyConsent', label: label_mrkpPrivacyConsent, disabled: true, componentType: ComponentType.Input },
  { name: 'businessName', label: label_sellerName, componentType: ComponentType.Input, maxLength: 200 },
  { name: 'city', label: label_sellerPrincipalCity, componentType: ComponentType.Input, maxLength: 200 },
  { name: 'brandName', label: label_sellerBrand, componentType: ComponentType.Input, maxLength: 200 },
  {
    name: 'siteUrl',
    label: label_sellerUrl,
    componentType: ComponentType.Input,
    placeholder: label_sellerPlaceholder,
    type: 'url',
    maxLength: 500,
  },
  { name: 'shortHistory', label: label_sellerShortStory, componentType: ComponentType.TextArea, maxLength: 4000 },
  {
    name: 'shopsQty',
    label: label_sellerNShops,
    componentType: ComponentType.InputNumber,
    min: 0,
    type: 'number',
    max: 999999999,
  },
  { name: 'sectorsId', label: label_sellerSectors, componentType: ComponentType.Select },
  { name: 'groupsId', label: label_sellerGroups, componentType: ComponentType.Select },
  { name: 'brandsId', label: label_sellerBrands, componentType: ComponentType.SelectAutoComplete },
  { name: 'contact', label: label_sellerContact, componentType: ComponentType.Input, maxLength: 2000 },
  {
    name: 'email',
    label: label_sellerCommercialContact,
    componentType: ComponentType.Input,
    maxLength: 50,
    type: 'email',
  },
  { name: 'pdf', label: label_sellerPdf, componentType: ComponentType.Upload },
]

export const sellerAuthorizedUsers = [UserType.Seller, UserType.Admin]
