import { Content } from 'antd/es/layout/layout'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { Button, Checkbox, Col, Input, Modal, Row, Select, Spin } from 'antd'
import { useNavigateWrapper } from '../../Helpers/NavigateHelper'
import { UserType } from '../../Interfaces/LoginInterfaces'
import {
  label_mrkpActivation,
  label_mrkpAdd,
  label_mrkpLBrands,
  label_mrkpLCategory,
  label_mrkpLCommercialInfo,
  label_mrkpLCommission,
  label_mrkpLConditions,
  label_mrkpLDiscount,
  label_mrkpLFixedfeeForItem,
  label_mrkpLFixedfeeForMonth,
  label_mrkpLMarket,
  label_mrkpLMonthlyExpenses,
  label_mrkpLName,
  label_mrkpLPackaging,
  label_mrkpLSector,
  label_mrkpLShipping,
  label_mrkpLStart,
  label_mrkpLTarget,
  label_mrkpShipping_items,
  label_mrkpVisibility,
  label_mrkpMyStore,
  label_mrkpFilter_options,
  label_mrkpLEnabled,
  label_mrkpSentEmail,
  label_mrkpSendFirstEmail,
  label_mrkpAffiliationLink,
  label_mrkpLOthers,
  label_mrkpLNameList,
  label_mrkpLType,
  label_mrkpLSeasonality,
  label_mrkpPrivacyConsent,
} from '../../Constants/Labels'
import MarketplaceTable from './Components/MarketplaceTable'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { isNotNullOrEmpty, isValidDate } from '../../Helpers/GenericHelper'
import { getMarketplaceList, getMarketsHub, initMarketplaceData } from '../../Services/MarketplaceService'
import { isValidResult } from '../../Helpers/ResultHelper'
import {
  MarketplaceData,
  MarketplaceDataKeys,
  ShippingMode,
  FilterOptions,
} from '../../Interfaces/MarketplaceInterfaces'
import { ColumnType } from 'antd/es/table'
import { SellerData } from '../../Interfaces/SellerInterfaces'
import { CheckOutlined, MailOutlined } from '@ant-design/icons'
import { getSellerData, saveAllMarketplaceVisibility, saveMarketplaceVisibility } from '../../Services/SellerService'
import { setSellerCurrentPage, setSeller } from '../../Slices/SellerSlice'
import { enum2Options } from 'antd-utils'
import { MENUKEYS } from '../../Constants/ClientRoutes'
import { setAdminCurrentPage } from '../../Slices/AdminSlice'
import { sellerAuthorizedUsers } from '../Seller/ItemsDefinitionSeller'
import { BindingElement } from '../../Interfaces/BindingInterface'
import Search from 'antd/es/input/Search'
import MailDialog, { MailData } from '../Components/MailDialog'
import { MarketplaceActivations } from '../../Interfaces/MarketplaceActivations'
import { smColWidth, mdColWidth, lgColWidth, xlColWidth } from '../../Constants/TableConst'
interface LocalColumn extends Exclude<ColumnType<MarketplaceData>, 'dataIndex' | 'key'> {
  dataIndex: MarketplaceDataKeys
  key: MarketplaceDataKeys
}
interface LocalColumnGroupType extends Omit<LocalColumn, 'dataIndex' | 'key'> {
  children: LocalColumn[]
}
export type MrkpColumnsType = (LocalColumnGroupType | LocalColumn)[]

export default function Marketplaces() {
  const dispatch = useAppDispatch()
  const userType: UserType = useAppSelector(state => state.present.login.userType)
  const entityId: number = useAppSelector(state => state.present.login.entityId)

  const currentSeller: SellerData = useAppSelector(state => state.present.seller.selected)
  const sectors: BindingElement[] = useAppSelector(state => state.present.main.sectors)
  const groups: BindingElement[] = useAppSelector(state => state.present.main.groups)
  const seasonalities: BindingElement[] = useAppSelector(state => state.present.main.seasonalities)
  const markets: BindingElement[] = useAppSelector(state => state.present.main.markets)
  const platformTypes: BindingElement[] = useAppSelector(state => state.present.main.platformTypes)

  const { navigateToMarketplaceSheet } = useNavigateWrapper()
  const [open, setOpen] = useState(false)
  const [modalEmail, setModalEmail] = useState('')
  const [modalHubId, setModalHubId] = useState(null)
  const [hubMarkets, setHubMarkets] = useState([])
  const [dataList, setDataList] = useState<MarketplaceData[] | null>(null)
  const [mailData, setMailData] = useState<MailData>(null)
  const [activationRows, setActivationRows] = useState<MarketplaceActivations[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isCheckingAll, setIsCheckingAll] = useState(false)
  const isUserAuthorized = sellerAuthorizedUsers.includes(userType)

  const showModal = () => {
    getMarketsHub().then(data => {
      if (isValidResult(data)) {
        setHubMarkets(data.payload)
        setOpen(true)
      }
    })
  }

  const handleMarketSelectChange = useCallback(value => {
    setModalHubId(value)
  }, [])

  const handleOk = (e: React.MouseEvent<HTMLElement>) => {
    setOpen(false)
    initMarketplaceData({ idHub: modalHubId, username: modalEmail }).then(data => {
      if (isValidResult(data)) {
        navigateToMarketplaceSheet(data.payload.id)
      }
    })
  }

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    setOpen(false)
    setModalEmail('')
    setModalHubId(null)
  }
  useEffect(() => {
    if (currentSeller) setActivationRows(currentSeller.activations)
  }, [currentSeller])

  const onSearch = text => {
    setIsLoading(true)
    getMarketplaceList({ filterNameOrBrand: text }).then(data => {
      if (isValidResult(data)) setDataList(data.payload)
      else setDataList([])
      setIsLoading(false)
    })
  }

  const onChangeSearch = e => !isNotNullOrEmpty(e) && onSearch('')

  useEffect(() => {
    getMarketplaceList().then(data => {
      if (isValidResult(data)) {
        setDataList(data.payload)
        setIsLoading(false)
      }
    })
  }, [])

  useEffect(() => {
    if (userType === UserType.Seller) {
      getSellerData(entityId).then(seller => {
        if (isValidResult(seller)) dispatch(setSeller(seller.payload))
      })
      dispatch(setSellerCurrentPage(MENUKEYS.Marketplaces))
    }
    if (userType === UserType.Admin) {
      dispatch(setAdminCurrentPage(MENUKEYS.Marketplaces))
    }
  }, [entityId, userType, dispatch])

  const dataTable = useMemo(() => {
    const mappedList = dataList?.map(d => ({
      ...d,
      sectorList: d.sectors?.map(e => e.name).join(',') || '',
      brandList: d.brands?.map(e => e.name).join(',') || '',
      groupList: d.groups?.map(e => e.name).join(',') || '',
      seasonalityList: d.seasonalities?.map(e => e.name).join(',') || '',
      marketList: d.markets?.map(e => e.name).join(',') || '',
      targetList: d.targets?.map(e => e.name).join(',') || '',
      platformTypeList: d.platformTypes?.map(e => e.name).join(',') || '',
    }))
    return mappedList
  }, [dataList])

  const marketplaceColumns: LocalColumn[] = [
    {
      title: `${label_mrkpLNameList}`,
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <a
          className='d-flex align-center justify-space-between'
          onClick={() =>
            record.privacyConsent || userType === UserType.Admin ? navigateToMarketplaceSheet(record.id) : null
          }
        >
          {text}
          <img src={`/repo/marketplace${record.id}/${record.logo}`} style={{ maxHeight: '50px', maxWidth: '50px' }} />
        </a>
      ),
      sorter: (a, b) => {
        return a.name.localeCompare(b.name)
      },
      width: lgColWidth,
    },
  ]

  const commercialInfoColumns: LocalColumn[] = useMemo(
    () =>
      [
        userType === UserType.Admin
          ? {
              title: `${label_mrkpLEnabled}`,
              dataIndex: 'isEnabled',
              key: 'isEnabled',
              render: (_text, record) => (record.isEnabled ? <CheckOutlined /> : null),
              onFilter: (value, record) => record.isEnabled === (value === FilterOptions.Yes),
              filters: enum2Options(FilterOptions).map((item: any) => ({
                value: item.value,
                text: label_mrkpFilter_options[item.value],
              })),
              width: smColWidth,
              className: 'columns-bg-1 text-center',
            }
          : null,
        userType === UserType.Admin
          ? {
              title: `${label_mrkpPrivacyConsent}`,
              dataIndex: 'privacyConsent',
              key: 'privacyConsent',
              render: (_text, record) => (record.privacyConsent ? <CheckOutlined /> : null),
              onFilter: (value, record) => record.privacyConsent === (value === FilterOptions.Yes),
              filters: enum2Options(FilterOptions).map((item: any) => ({
                value: item.value,
                text: label_mrkpFilter_options[item.value],
              })),
              width: smColWidth,
              className: 'columns-bg-1 text-center',
            }
          : null,
        {
          title: `${label_mrkpLType}`,
          dataIndex: 'platformTypeList',
          key: 'platformTypeList',
          render: text => <p> {text}</p>,
          onFilter: (value, record) => record.platformTypeList.includes(value),
          filters: platformTypes.map((item: any) => ({
            value: item.name,
            text: item.name,
          })),
          width: lgColWidth,
          className: 'columns-bg-1',
        },
        {
          title: `${label_mrkpLStart}`,
          dataIndex: 'year',
          key: 'year',
          sorter: (a, b) => {
            return a.year.localeCompare(b.year)
          },
          width: mdColWidth,
          className: 'columns-bg-1',
        },
        {
          title: `${label_mrkpLMarket}`,
          dataIndex: 'marketList',
          key: 'marketList',
          width: xlColWidth,
          render: text => {
            return <p> {text}</p>
          },
          onFilter: (value, record) => record.marketList.includes(value),
          filters: markets.map((item: any) => ({
            value: item.name,
            text: item.name,
          })),
          className: 'columns-bg-1',
        },
        {
          title: `${label_mrkpLSector}`,
          dataIndex: 'sectorList',
          key: 'sectorList',
          width: xlColWidth,
          render: text => {
            return <p> {text}</p>
          },
          onFilter: (value, record) => record.sectorList.includes(value),
          filters: sectors.map((item: any) => ({
            value: item.name,
            text: item.name,
          })),
          className: 'columns-bg-1',
        },
        {
          title: `${label_mrkpLCategory}`,
          dataIndex: 'groupList',
          key: 'groupList',
          width: xlColWidth,
          render: text => {
            return <p> {text}</p>
          },
          onFilter: (value, record) => record.groupList.includes(value),
          filters: groups.map((item: any) => ({
            value: item.name,
            text: item.name,
          })),
          className: 'columns-bg-1',
        },
        {
          title: `${label_mrkpLSeasonality}`,
          dataIndex: 'seasonalityList',
          key: 'seasonalityList',
          width: mdColWidth,
          render: text => {
            return <p> {text}</p>
          },
          onFilter: (value, record) => record.seasonalityList.includes(value),
          filters: seasonalities.map((item: any) => ({
            value: item.name,
            text: item.name,
          })),
          className: 'columns-bg-1',
        },
        {
          title: `${label_mrkpLTarget}`,
          dataIndex: 'targetList',
          key: 'targetList',
          width: mdColWidth,
          className: 'columns-bg-1',
        },
        userType === UserType.Admin
          ? {
              title: `${label_mrkpLBrands}`,
              dataIndex: 'brandList',
              key: 'brandList',
              width: xlColWidth,
              className: 'columns-bg-1',
            }
          : null,
      ].filter(e => e !== null) as LocalColumn[],
    [userType, dispatch, markets, groups, sectors],
  )

  const conditionColumns: LocalColumn[] = [
    {
      title: `${label_mrkpLDiscount}`,
      dataIndex: 'hasDiscounts',
      key: 'hasDiscounts',
      render: (_text, record) => (record.hasDiscounts ? <CheckOutlined /> : null),
      onFilter: (value, record) => record.hasDiscounts === (value === FilterOptions.Yes),
      filters: enum2Options(FilterOptions).map((item: any) => ({
        value: item.value,
        text: label_mrkpFilter_options[item.value],
      })),
      width: smColWidth,
      className: 'columns-bg-2 text-center',
    },
    {
      title: `${label_mrkpLCommission}`,
      dataIndex: 'hasComminsions',
      key: 'hasComminsions',
      render: (_text, record) => (record.hasComminsions ? <CheckOutlined /> : null),
      onFilter: (value, record) => record.hasComminsions === (value === FilterOptions.Yes),
      filters: enum2Options(FilterOptions).map((item: any) => ({
        value: item.value,
        text: label_mrkpFilter_options[item.value],
      })),
      width: smColWidth,
      className: 'columns-bg-2 text-center',
    },
    {
      title: `${label_mrkpLFixedfeeForItem}`,
      dataIndex: 'hasFixedFeeForItem',
      key: 'hasFixedFeeForItem',
      render: (_text, record) => (record.hasFixedFeeForItem ? <CheckOutlined /> : null),
      onFilter: (value, record) => record.hasFixedFeeForItem === (value === FilterOptions.Yes),
      filters: enum2Options(FilterOptions).map((item: any) => ({
        value: item.value,
        text: label_mrkpFilter_options[item.value],
      })),
      width: smColWidth,
      className: 'columns-bg-2 text-center',
    },
    {
      title: `${label_mrkpLFixedfeeForMonth}`,
      dataIndex: 'hasFixedFeeForMonth',
      key: 'hasFixedFeeForMonth',
      render: (_text, record) => (record.hasFixedFeeForMonth ? <CheckOutlined /> : null),
      onFilter: (value, record) => record.hasFixedFeeForMonth === (value === FilterOptions.Yes),
      filters: enum2Options(FilterOptions).map((item: any) => ({
        value: item.value,
        text: label_mrkpFilter_options[item.value],
      })),
      width: smColWidth,
      className: 'columns-bg-2 text-center',
    },
    {
      title: `${label_mrkpLMonthlyExpenses}`,
      dataIndex: 'hasMonthlyExpenses',
      key: 'hasMonthlyExpenses',
      render: (_text, record) => (record.hasMonthlyExpenses ? <CheckOutlined /> : null),
      onFilter: (value, record) => record.hasMonthlyExpenses === (value === FilterOptions.Yes),
      filters: enum2Options(FilterOptions).map((item: any) => ({
        value: item.value,
        text: label_mrkpFilter_options[item.value],
      })),
      width: smColWidth,
      className: 'columns-bg-2 text-center',
    },
    {
      title: `${label_mrkpLPackaging}`,
      dataIndex: 'hasPackaging',
      key: 'hasPackaging',
      render: (_text, record) => (record.hasPackaging ? <CheckOutlined /> : null),
      onFilter: (value, record) => record.hasPackaging === (value === FilterOptions.Yes),
      filters: enum2Options(FilterOptions).map((item: any) => ({
        value: item.value,
        text: label_mrkpFilter_options[item.value],
      })),
      width: smColWidth,
      className: 'columns-bg-2 text-center',
    },
  ]

  const othersColumns: LocalColumn[] = [
    {
      title: `${label_mrkpLShipping}`,
      dataIndex: 'shippingMode',
      key: 'shippingMode',
      render: text => <p> {label_mrkpShipping_items[text]}</p>,
      onFilter: (value, record) => record.shippingMode === value,
      filters: enum2Options(ShippingMode).map((item: any) => ({
        value: item.value,
        text: label_mrkpShipping_items[item.value],
      })),
      width: mdColWidth,
      className: 'columns-bg-3',
    },
  ]

  const storeColumns: LocalColumn[] = useMemo(
    () => [
      {
        title: () => {
          return (
            <>
              <Checkbox
                onChange={e => {
                  setIsCheckingAll(true)
                  saveAllMarketplaceVisibility(currentSeller.id, e.target.checked).then(data => {
                    if (isValidResult(data)) {
                      setIsCheckingAll(false)
                      dispatch(
                        setSeller({
                          ...currentSeller,
                          marketplaceVisibilities: [
                            ...currentSeller.marketplaceVisibilities.map(x => ({
                              ...x,
                              visible: data.payload.visible,
                            })),
                          ],
                        }),
                      )
                    }
                  })
                }}
              />
              {' ' + label_mrkpVisibility}
            </>
          )
        },
        dataIndex: 'visibility',
        key: 'visibility',
        render: (_text, record) => {
          const visibilityRecord = currentSeller.marketplaceVisibilities.find(e => e.marketplaceId == record.id)
          return isCheckingAll ? (
            <Spin spinning={true} />
          ) : (
            <Checkbox
              indeterminate={!visibilityRecord}
              checked={visibilityRecord?.visible}
              onChange={e => {
                saveMarketplaceVisibility(currentSeller.id, record.id, e.target.checked).then(data => {
                  if (isValidResult(data))
                    dispatch(
                      setSeller({
                        ...currentSeller,
                        marketplaceVisibilities: [
                          ...currentSeller.marketplaceVisibilities.filter(e => e.marketplaceId !== record.id),
                          { marketplaceId: data.payload.idMarketplace, visible: data.payload.visible },
                        ],
                      }),
                    )
                })
              }}
            />
          )
        },
        sorter: (a, b) => a.name.length - b.name.length,
        width: lgColWidth,
        className: 'text-center fixedR-column-1',
      },
      {
        title: `${label_mrkpActivation}`,
        dataIndex: 'activations',
        key: 'activations',
        render: (_text, record) => {
          const activationRow = activationRows.find(e => e.marketplaceId == record.id)
          return activationRow?.activation && isValidDate(activationRow.activation) ? (
            <CheckOutlined />
          ) : record.contactEmail ? (
            <>
              <Button
                icon={
                  <MailOutlined
                    onClick={() => {
                      setMailData({
                        isOpen: true,
                        marketplaceName: record.name,
                        marketplaceId: record.id,
                        sellerId: currentSeller.id,
                      })
                    }}
                  />
                }
              ></Button>
              {activationRow?.sentEmail > 0 ? (
                <>
                  {activationRow.sentEmail} {label_mrkpSentEmail}
                </>
              ) : (
                <>{label_mrkpSendFirstEmail}</>
              )}
            </>
          ) : (
            <>
              {record.affiliationLink ? (
                <a href={record.affiliationLink} target='_blank' rel='noopener noreferrer'>
                  {label_mrkpAffiliationLink}
                </a>
              ) : (
                <Button
                  icon={
                    <MailOutlined
                      onClick={() => {
                        setMailData({
                          isOpen: true,
                          marketplaceName: record.name,
                          marketplaceId: record.id,
                          sellerId: currentSeller.id,
                        })
                      }}
                    />
                  }
                ></Button>
              )}
            </>
          )
        },

        // sorter: (a, b) => a.name.length - b.name.length,
        width: lgColWidth,
        className: 'columns-bg-4 fixedR-column-2',
      },
    ],
    [userType, currentSeller, activationRows, isCheckingAll, dispatch],
  )

  const columns: MrkpColumnsType = [
    {
      title: `${label_mrkpLName}`,
      children: marketplaceColumns,
    },
    {
      title: `${label_mrkpLCommercialInfo}`,
      children: commercialInfoColumns,
    },
    {
      title: `${label_mrkpLConditions}`,
      children: conditionColumns,
    },
    {
      title: `${label_mrkpLOthers}`,
      children: othersColumns,
    },
    ...(userType === UserType.Seller && currentSeller
      ? [
          {
            title: `${label_mrkpMyStore}`,
            children: storeColumns,
            className: 'fixedR-column-2',
          },
        ]
      : []),
  ]

  return isUserAuthorized ? (
    <>
      <Content className='page-content bg-white pa-6 pt-0' id='page-content-marketplaces'>
        {userType === UserType.Admin ? (
          <Row className='pr-6 pt-5 pl-6'>
            <Col>
              <Button type='primary' className='new-marketplace-button' onClick={showModal}>
                {label_mrkpAdd}
              </Button>
              <Modal
                title='Insert new marketplace sheet'
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{ disabled: modalHubId === null }} //&& !isNotNullOrEmpty(modalEmail)
              >
                <Select
                  placeholder='market'
                  className='w-100'
                  value={modalHubId}
                  options={hubMarkets.map(e => ({ label: e.marketName, value: e.idHub }))}
                  onChange={handleMarketSelectChange}
                ></Select>
                <Input
                  value={modalEmail}
                  placeholder='email'
                  onChange={e => setModalEmail(e.target.value)}
                  style={{ display: 'none' }} //non inseriscono più le credenziali da qui
                />
              </Modal>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col>
            <Search
              className='pr-6 pt-5 pl-6'
              style={{ width: 800 }}
              placeholder={
                userType == UserType.Admin
                  ? `${label_mrkpLName}/${label_mrkpLBrands}`.toLowerCase()
                  : `${label_mrkpLName}`.toLowerCase()
              }
              onSearch={onSearch}
              enterButton
              allowClear
              onChange={onChangeSearch}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <MarketplaceTable columns={columns} marketplacesData={dataTable} isLoading={isLoading} />
          </Col>
        </Row>
      </Content>
      <MailDialog
        mailData={mailData}
        onClose={() => setMailData({ isOpen: false })}
        emailTo='marketplace'
        contactEmail={currentSeller?.email}
        onEmailSent={marketplaceId => {
          const updated: MarketplaceActivations[] = JSON.parse(JSON.stringify(activationRows))
          const activationRow = updated.find(x => x.marketplaceId == marketplaceId)
          if (activationRow) {
            activationRow.sentEmail += 1
          } else {
            updated.push({
              sentEmail: 1,
              marketplaceId: marketplaceId,
              sellerId: currentSeller.id,
            })
          }
          setActivationRows(updated)
        }}
      />
    </>
  ) : null
}
