import { createSlice } from '@reduxjs/toolkit'
import { ActionLogin, UserType } from '../Interfaces/LoginInterfaces'

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    username: '',
    userType: UserType.Marketplace,
    userId: 0,
    entityId: 0,
    entityHubId: 0,
    action: ActionLogin.Username,
  },
  reducers: {
    setUser: (state, action) => {
      state.username = action.payload.username
      state.userType = action.payload.userType
      state.userId = action.payload.userId
      state.entityId = action.payload.entityId
    },
    setLoginAction: (state, action) => {
      state.action = action.payload
    },
    setUsername: (state, action) => {
      state.username = action.payload
    },
    setLoginDefault: state => {
      state.username = ''
      state.action = ActionLogin.Username
    },
  },
})

export const { setUser, setLoginAction, setUsername, setLoginDefault } = loginSlice.actions
export default loginSlice.reducer
