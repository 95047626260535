import { notification } from 'antd'
import { Env, LocalhostUrl } from '../Constants/Configuration'

export const isEncoded = value => {
  value = value || ''

  return value !== decodeURIComponent(value)
}

export const notifyError = (title: string, err: BackendResultError) => {
  console.log(title, err)
  notification.error({
    message: title,
    description: err.message,
    placement: 'topRight',
    duration: 5,
  })
}
export const notifyResultlessError = (title: string, message: string) => {
  console.log(title, message)
  notification.error({
    message: title,
    description: message,
    placement: 'topRight',
    duration: 5,
  })
}
const isDevelopment = () => {
  return (
    (!!process.env.NODE_ENV && process.env.NODE_ENV === Env.Develop) ||
    (!!process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === Env.Develop)
  )
}

const isDemoSite = () => {
  return !!process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === Env.Demo
}

export const isProdSite = () => {
  return !!process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === Env.Prod
}
const isLocalhost = () => {
  return window.location.href.indexOf('127.0.0.1') !== -1 || window.location.href.indexOf('localhost') !== -1
}
const getBaseUrl = () => {
  if (isLocalhost() && isDevelopment()) {
    return (!!process.env.REACT_APP_LOCALHOST ? process.env.REACT_APP_LOCALHOST : LocalhostUrl)?.replace(/\/?$/, '')
  } else {
    return ''
  }
}
const createRequestOptions = (method, body, includeCredentials, enableCors, stringify: boolean) => {
  return {
    method: method,
    headers: stringify ? { 'Content-Type': 'application/json' } : {},
    body: body ? (stringify ? JSON.stringify(body) : body) : undefined,
    credentials: (includeCredentials ? 'include' : undefined) as RequestCredentials,
    mode: (enableCors ? 'cors' : undefined) as RequestMode,
    cache: 'no-cache' as RequestCache,
  }
}

export async function createRequest<T>(
  url: string,
  method: string,
  body: {},
  token: string,
  includeCredentials = false,
  enableCors = false,
): Promise<BackendResult<T>> {
  try {
    const requestOptions = createRequestOptions(method, body, includeCredentials, enableCors, true)
    if (token) requestOptions.headers['Authorization'] = `Bearer ${token}`
    const response = await fetch(getBaseUrl() + url, requestOptions)
    if (response) {
      if (response.ok) {
        return response.json().then(data => Promise.resolve({ code: 'success', payload: data }))
      } else {
        return response.text().then(text => Promise.resolve({ code: 'error', message: text ?? response.statusText }))
      }
    }
  } catch (err) {
    return Promise.resolve({
      code: 'error',
      message: err && err.message ? err.message : err && err.stack ? err.stack : '',
    })
  }
}

export async function createRequestNoStringifyBody<T>(
  url: string,
  method: string,
  body: {},
  token: string,
  includeCredentials = false,
  enableCors = false,
): Promise<BackendResult<T>> {
  try {
    let requestOptions = createRequestOptions(method, body, includeCredentials, enableCors, false)
    if (token) requestOptions.headers['Authorization'] = `Bearer ${token}`
    const response = await fetch(getBaseUrl() + url, requestOptions)
    if (response) {
      if (response.ok) {
        return response.json().then(data => Promise.resolve({ code: 'success', payload: data }))
      } else {
        return response.text().then(text => Promise.resolve({ code: 'error', message: text ?? response.statusText }))
      }
    }
  } catch (err) {
    return Promise.resolve({
      code: 'error',
      message: err && err.message ? err.message : err && err.stack ? err.stack : '',
    })
  }
}

export type BackendResultError = {
  code: 'error'
  message: string
  msgCode?: string
  msgParameters?: string[]
}
export type BackendResultOk<T = any> = { code: 'success'; payload?: T }

export type BackendResult<T = any> = BackendResultOk<T> | BackendResultError

export const isError = (result: BackendResult): result is BackendResultError => {
  if (result.code === 'error') return true
}

export function isNotNullOrEmpty(value: string | null) {
  return value && value !== ''
}

export const isNotNullOrEmptyUrl = value => {
  return value !== null && value !== undefined && value !== ''
}

export const isObjectEmpty = (value: Object) => {
  return Object.keys(value).length === 0
}

export const isValidDate = inputDate => {
  return !isNaN(Date.parse(inputDate))
}

export const scrollToBottom = () => {
  window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
}
