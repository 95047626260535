import { decrypt, encrypt } from './EncodeHelper'
import { label_Config } from '../Constants/Labels'
import { UserInfo, UserType } from '../Interfaces/LoginInterfaces'
import { useAppDispatch } from '../hooks'
import { setTokenToSessionStorage } from './TokenHelper'
import { setUser } from '../Slices/LoginSlice'
import { useNavigateWrapper } from './NavigateHelper'
import { isProdSite } from './GenericHelper'

export const getLoginInfo = values => {
  console.log('getLoginInfo', values)
  return {
    userId: values.id,
    userType: values.userType,
    username: values.username,
    entityId: values.entityId,
    privacyConsent: values.privacyConsent,
  }
}

export const setUserInfoToSessionStorage = (userInfo: UserInfo) => {
  sessionStorage.setItem(label_Config, encrypt(JSON.stringify(userInfo), '5L5]H6Xnq[^G42E6yuw|4_lBV8[_fM'))
}
export const getUserInfoFromSessionStorage = (): string => {
  return JSON.parse(decrypt(sessionStorage.getItem(label_Config), '5L5]H6Xnq[^G42E6yuw|4_lBV8[_fM'))
}
export const clearUserInfoFromSessionStorage = () => {
  sessionStorage.removeItem(label_Config)
}

export const useUserLogin = () => {
  const dispatch = useAppDispatch()
  const { navigateToMarketplaces, navigateToMarketplaceSheet, navigateToAdmin, navigateToSellers, navigateToPrivacy } =
    useNavigateWrapper()
  const handleUserLogin = (token: string, payload: any) => {
    setTokenToSessionStorage(token)
    const loginInfo: UserInfo = getLoginInfo(payload)
    dispatch(setUser(loginInfo))
    setUserInfoToSessionStorage(loginInfo)
    if (loginInfo.userType != UserType.Admin && !loginInfo.privacyConsent) {
      navigateToPrivacy()
      return
    }
    switch (loginInfo.userType) {
      case UserType.Seller:
        navigateToMarketplaces()
        break
      case UserType.Marketplace:
        isProdSite() ? navigateToSellers() : navigateToMarketplaceSheet(loginInfo.entityId)
        break
      case UserType.Admin:
        navigateToAdmin()
        break
      default:
        break
    }
  }

  return { handleUserLogin }
}
