import { createRequest, isError, notifyError } from '../Helpers/GenericHelper'
import { InvalidResult, ValidResult } from '../Helpers/ResultHelper'
import { getTokenFromSessionStorage } from '../Helpers/TokenHelper'
import { BindingElement, BindingType } from '../Interfaces/BindingInterface'

export const getBindingData = async (type: BindingType) => {
  const url = `/api/binding/data?type=${type}`
  const token = getTokenFromSessionStorage()

  const result = await createRequest<[]>(url, 'GET', null, token, true, true)
  if (isError(result)) {
    notifyError('getBindingData', result)
    return InvalidResult<BindingElement[]>()
  } else return ValidResult(result.payload)
}

export const getAutocompleteData = async (type: BindingType, value: string) => {
  const url = `/api/binding/autocomplete?type=${type}&value=${value}`
  const token = getTokenFromSessionStorage()

  const result = await createRequest<[]>(url, 'GET', null, token, true, true)
  if (isError(result)) {
    notifyError('getAutocompleteData', result)
    return InvalidResult<BindingElement[]>()
  } else return ValidResult(result.payload)
}
