import { Button, Col, Row } from 'antd'
import { Content } from 'antd/es/layout/layout'
import Logosvg from '../Svg/Logo'
import { useAppDispatch, useAppSelector } from '../hooks'
import { registerPrivacy } from '../Services/PrivacyService'
import { isValidResult } from '../Helpers/ResultHelper'
import { useNavigateWrapper } from '../Helpers/NavigateHelper'
import { setLoginAction } from '../Slices/LoginSlice'
import { ActionLogin, UserType } from '../Interfaces/LoginInterfaces'
import { getTokenFromSessionStorage } from '../Helpers/TokenHelper'
import { useUserLogin } from '../Helpers/LoginHelper'

export default function Privacy() {
  const userId: number = useAppSelector(state => state.present.login.userId)
  const userType: UserType = useAppSelector(state => state.present.login.userType)
  const entityId: number = useAppSelector(state => state.present.login.entityId)
  const username: string = useAppSelector(state => state.present.login.username)
  console.log('privacy', userId, userType, entityId, username)

  const { navigateToLogin } = useNavigateWrapper()
  const dispatch = useAppDispatch()
  const token = getTokenFromSessionStorage()
  const { handleUserLogin } = useUserLogin()

  const setPrivacy = async (accept: boolean) => {
    const result = await registerPrivacy(userId, accept)
    if (isValidResult(result)) {
      if (accept) {
        handleUserLogin(token, {
          id: userId,
          userType: userType,
          username: username,
          entityId: entityId,
          privacyConsent: true,
        })
      } else {
        navigateToLogin()
        dispatch(setLoginAction(ActionLogin.Username))
      }
    }
  }

  return (
    <Content className='page-content login' id='page-content-login'>
      <Row justify='center' align='middle' style={{ height: '100vh' }}>
        <Col xxl={4} xl={6} lg={8} md={14} sm={16}>
          <div className='pa-5 text-center logo'>
            <Logosvg />
          </div>
          <Col className='privacy-title'>Privacy</Col>
          <Col>
            <div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc lacinia eleifend lorem vel egestas. Aliquam
              ultrices porttitor nulla quis mattis. Aliquam erat volutpat. Curabitur sed mollis ligula. Vivamus ac enim
              quis purus euismod tincidunt. Aliquam lobortis urna in bibendum faucibus. Aenean et neque quis ex
              vestibulum fringilla non sed risus. Duis id finibus felis, ac consequat dolor.
            </div>
          </Col>

          <Button className='login-form-button' onClick={() => setPrivacy(true)}>
            Accept
          </Button>
          <Button type='link' className='privacy-decline' onClick={() => setPrivacy(false)}>
            Decline
          </Button>
        </Col>
      </Row>
    </Content>
  )
}
