import { createRequest, isError, notifyError } from '../Helpers/GenericHelper'
import { InvalidResult, ValidResult } from '../Helpers/ResultHelper'
import { getTokenFromSessionStorage } from '../Helpers/TokenHelper'

export const registerPrivacy = async (userId: number, accept: boolean) => {
  const url = `/api/privacy/register?id=${userId}&accept=${accept}`
  const token = getTokenFromSessionStorage()

  const result = await createRequest(url, 'POST', null, token, true, true)
  if (isError(result)) {
    notifyError('setPrivacy', result)
    return InvalidResult()
  } else return ValidResult(result.payload)
}
