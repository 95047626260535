import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { BindingElement, BindingType } from '../../../Interfaces/BindingInterface'
import { SellerData, SellerDataKeys } from '../../../Interfaces/SellerInterfaces'
import {
  label_groupsId,
  label_mrkpCommercialInfo,
  label_save,
  label_sectorsId,
  label_sellerDisabled,
  label_sellerEnabled,
} from '../../../Constants/Labels'
import { EyeOutlined } from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getAutocompleteData } from '../../../Services/BindingService'
import { isValidResult } from '../../../Helpers/ResultHelper'
import { DefaultOptionType } from 'antd/es/select'
import SelectAutocomplete from '../../Components/SelectAutocomplete'
import { ComponentType, UploadFileType } from '../../../Constants/SheetFieldInterface'
import FileUploader from '../../Components/FileUploader'
import { RcFile } from 'antd/es/upload'
import { UserType } from '../../../Interfaces/LoginInterfaces'
import { isObjectEmpty, scrollToBottom } from '../../../Helpers/GenericHelper'
import TypedFormItem from '../../Components/TypedFormItem'
import { editedSellerFields, resetSellerEdit } from '../../../Slices/SellerSlice'
import { sellerFormItems, sellerUserRestrictedFields } from '../ItemsDefinitionSeller'
import ResetButton from '../../Components/ResetButton'
import { handleFieldChange, originalFile } from '../../../Helpers/SheetHelper'
import { CheckOutlined } from '@ant-design/icons'
import { useNavigateWrapper } from '../../../Helpers/NavigateHelper'

type SellerFormProps = {
  currentSeller: SellerData
  onSubmit: (values: SellerData, file: RcFile) => unknown
  onEnable: (value: boolean) => unknown
}

export const LocalFormItem = TypedFormItem<SellerDataKeys>
export default function SellerForm(props: SellerFormProps) {
  const dispatch = useAppDispatch()
  const sectors: BindingElement[] = useAppSelector(state => state.present.main.sectors)
  const groups: BindingElement[] = useAppSelector(state => state.present.main.groups)
  const editedFields = useAppSelector(state => state.present.seller.editedFields)
  const [form] = Form.useForm()
  const brands = useRef([...props.currentSeller.brands])

  const originalPdf = originalFile('seller', props.currentSeller.id, props.currentSeller.pdf)

  const [pdfUrl, setPdfUrl] = useState<string>(originalPdf)
  const [pdfFileName, setPdfFileName] = useState(null)
  const userType: UserType = useAppSelector(state => state.present.login.userType)
  const { navigateToPrivacy } = useNavigateWrapper()

  const autocompleteBrands = (text: string) =>
    getAutocompleteData(BindingType.Brand, text).then(response =>
      Promise.resolve(isValidResult(response) ? response.payload.map(e => ({ label: e.name, value: e.id })) : []),
    )

  const onSelectBrand = (option: DefaultOptionType) => {
    brands.current.push({ id: parseInt(`${option.value}`), name: option.label.toString() })
  }

  const handleOptionsMapping = useCallback(
    (option: string) => {
      if (option === label_sectorsId) {
        return sectors.map(e => ({ label: e.name, value: e.id }))
      }
      if (option === label_groupsId) {
        return groups.map(e => ({ label: e.name, value: e.id }))
      }
      return null
    },
    [sectors, groups],
  )
  const enableButton = () => {
    const isEnabled = props.currentSeller.isEnabled
    return userType === UserType.Admin ? (
      <>
        <div
          className={`sheet-enabled-div ${isEnabled ? 'sheet-enabled-text' : 'sheet-disabled-text'}`}
          onClick={event => {
            props.onEnable(!isEnabled)
            event.stopPropagation()
          }}
        >
          {isEnabled ? label_sellerEnabled : label_sellerDisabled}
          <CheckOutlined className={isEnabled ? 'sheet-enabled-icon' : 'sheet-disabled-icon'} />
        </div>
      </>
    ) : null
  }
  const generateForm = useMemo(() => {
    return (
      <>
        {sellerFormItems.map((item, index) => {
          const { name, disabled, componentType, maxLength, type, placeholder, max, min, label } = item
          const isFieldEdited = Object.keys(editedFields).includes(name) ? ' modified' : ''
          const isDisable = disabled || userType === UserType.Marketplace
          const coloredLine = index % 2 === 0 ? ' color-line' : ' bg-white'
          const fileType = name === 'pdf' ? UploadFileType.Pdf : null
          const getChange = value =>
            handleFieldChange(value, name, props.currentSeller, editedFields, editedSellerFields, dispatch)

          const isFieldVisible =
            (name === 'id' && userType !== UserType.Admin) ||
            (name === 'username' && userType !== UserType.Admin) ||
            (name === 'privacyConsent' && userType !== UserType.Admin)
              ? false
              : !sellerUserRestrictedFields.includes(name) || userType !== UserType.Marketplace

          return (
            isFieldVisible && (
              <Row key={name}>
                <Col span={7} className={'sheet-form-labels pl-4 pr-4 d-flex justify-space-between' + coloredLine}>
                  {label}
                  {sellerUserRestrictedFields.includes(name) && userType !== UserType.Marketplace && (
                    <EyeOutlined onClick={scrollToBottom} />
                  )}
                </Col>
                <Col span={17} className={'sheet-form-inputs pl-6 pr-6 pt-3' + coloredLine}>
                  <LocalFormItem name={name}>
                    {componentType === ComponentType.Input && (
                      <Input
                        className={'sheet-input sheet-text' + isFieldEdited}
                        disabled={isDisable}
                        onBlur={e => getChange(e.target.value)}
                        maxLength={maxLength}
                        type={type}
                        placeholder={placeholder}
                      />
                    )}
                    {componentType === ComponentType.TextArea && (
                      <TextArea
                        className={'sheet-text-area sheet-text' + isFieldEdited}
                        disabled={isDisable}
                        onBlur={e => getChange(e.target.value)}
                        maxLength={maxLength}
                      />
                    )}
                    {componentType === ComponentType.InputNumber && (
                      <InputNumber
                        className={'sheet-input-number sheet-text' + isFieldEdited}
                        disabled={isDisable}
                        onBlur={e => getChange(e.target.value)}
                        type={type}
                        max={max}
                        min={min}
                      />
                    )}
                    {componentType === ComponentType.Select && (
                      <Select
                        className={'sheet-select-multiple' + isFieldEdited}
                        mode='multiple'
                        disabled={isDisable}
                        allowClear
                        options={handleOptionsMapping(name)}
                        onChange={e => getChange(e.toString())}
                        maxTagCount={'responsive'}
                      />
                    )}
                    {componentType === ComponentType.SelectAutoComplete && (
                      <SelectAutocomplete
                        className={'sheet-select-autocomplete' + isFieldEdited}
                        mode='multiple'
                        initialvalues={brands.current?.map(e => ({ label: e.name, value: e.id }))}
                        onAutocompleteSearch={autocompleteBrands}
                        onSelectOption={onSelectBrand}
                        disabled={isDisable}
                        onChange={e => getChange(e.toString())}
                      />
                    )}

                    {componentType === ComponentType.Upload ? (
                      <FileUploader
                        setFileUrl={setPdfUrl}
                        fileUrl={pdfUrl}
                        fileType={fileType}
                        onFileSet={setPdfFileName}
                        formUserType={UserType.Seller}
                      />
                    ) : null}
                  </LocalFormItem>
                </Col>
              </Row>
            )
          )
        })}
      </>
    )
  }, [handleOptionsMapping, pdfUrl, editedFields, dispatch, props.currentSeller])

  const submitButton = useMemo(() => {
    return (
      <Row justify={'end'} style={{ width: '100%' }} className='pt-3'>
        <Col span={8}>
          <Form.Item>
            <Button
              htmlType='submit'
              className={'sheet-form-button' + (!isObjectEmpty(editedFields) ? ' active' : ' disabled')}
              disabled={isObjectEmpty(editedFields)}
            >
              {label_save}
            </Button>
            {userType === UserType.Seller ? (
              <>
                <br />
                <br />
                <Button type='link' className='login-forgot-password ' onClick={navigateToPrivacy}>
                  Privacy
                </Button>
              </>
            ) : null}
          </Form.Item>
        </Col>
      </Row>
    )
  }, [editedFields])

  useEffect(() => {
    //fix to properly show data after submit
    form.setFieldsValue(props.currentSeller)
  }, [form, props.currentSeller])

  return (
    <>
      <Row align={'middle'} className='sheet-form-header'>
        <Col span={7} className='sheet-collapse-header pl-4 pr-0'>
          {label_mrkpCommercialInfo}
        </Col>

        {/* <ResetButton form={form} currentSeller={props.currentSeller} resetEdit={resetSellerEdit} /> */}
        {enableButton()}
      </Row>
      <Form
        form={form}
        name='basic'
        initialValues={props.currentSeller}
        onFinish={value => {
          if (editedFields['shopsQty'] === '') value.shopsQty = 0
          props.onSubmit(value, pdfFileName)
        }}
        autoComplete='off'
        layout='vertical'
      >
        {generateForm}
        {submitButton}
      </Form>
    </>
  )
}
