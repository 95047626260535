import { createRequest, isError, notifyError } from '../Helpers/GenericHelper'
import { InvalidResult, ValidResult } from '../Helpers/ResultHelper'
import { getTokenFromSessionStorage } from '../Helpers/TokenHelper'
import { Filter } from '../Interfaces/FilterInterface'
import {
  MarketHub,
  MarketplaceCredentials,
  MarketplaceData,
  MarketplaceSaveResponse,
} from '../Interfaces/MarketplaceInterfaces'

export const saveMarketplaceData = async (data: MarketplaceData) => {
  const url = `/api/marketplace`
  const token = getTokenFromSessionStorage()
  const result = await createRequest<MarketplaceSaveResponse>(url, 'POST', data, token, true, true)
  if (isError(result)) {
    notifyError('saveMarketplaceData', result)
    return InvalidResult<MarketplaceSaveResponse>()
  } else return ValidResult(result.payload)
}

export const getMarketplaceData = async (id: number) => {
  const url = `/api/marketplace/${id}/data`
  const token = getTokenFromSessionStorage()

  const result = await createRequest<MarketplaceData>(url, 'GET', null, token, true, true)
  if (isError(result)) {
    notifyError('getMarketplaceData', result)
    return InvalidResult<MarketplaceData>()
  } else return ValidResult(result.payload)
}

export const getMarketsHub = async () => {
  const url = `/api/marketplace/getmarkets`
  const token = getTokenFromSessionStorage()

  const result = await createRequest<MarketHub[]>(url, 'GET', null, token, true, true)
  if (isError(result)) {
    notifyError('getMarketsHub', result)
    return InvalidResult<MarketHub[]>()
  } else return ValidResult(result.payload)
}

export const initMarketplaceData = async (data: MarketplaceCredentials) => {
  const url = `/api/marketplace/init`
  const token = getTokenFromSessionStorage()
  const result = await createRequest<MarketplaceSaveResponse>(url, 'POST', data, token, true, true)
  if (isError(result)) {
    notifyError('initMarketplaceData', result)
    return InvalidResult<MarketplaceSaveResponse>()
  } else return ValidResult(result.payload)
}

export const getMarketplaceList = async (filter?: Filter) => {
  const url = `/api/marketplace/getlist`
  const token = getTokenFromSessionStorage()

  const body = filter || { filterNameOrBrand: '' }
  const result = await createRequest<MarketplaceData[]>(url, 'POST', body, token, true, true)
  if (isError(result)) {
    notifyError('getMarketplaceList', result)
    return InvalidResult<MarketplaceData[]>()
  } else return ValidResult(result.payload)
}

export const enableMarketplace = async (id: number, value: boolean) => {
  const url = `/api/marketplace/enable?id=${id}&value=${value}`
  const token = getTokenFromSessionStorage()

  const result = await createRequest(url, 'POST', null, token, true, true)
  if (isError(result)) {
    notifyError('enableMarketplace', result)
    return InvalidResult<MarketplaceData[]>()
  } else return ValidResult(result.payload)
}
