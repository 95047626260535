import { Button, Col, notification, Row } from 'antd'
import { useEffect, useState } from 'react'
import { UserType } from '../../Interfaces/LoginInterfaces'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { MENUKEYS } from '../../Constants/ClientRoutes'
import { setAdminCurrentPage } from '../../Slices/AdminSlice'
import { label_AlignBrands, label_OperationCompleted, label_SendMarketplaceInvitation } from '../../Constants/Labels'
import { alignBrands } from '../../Services/AdminService'
import { isValidResult } from '../../Helpers/ResultHelper'
import { sendMarketplaceInvitations } from '../../Services/MailService'

export default function Dashboard() {
  const dispatch = useAppDispatch()
  const userType: UserType = useAppSelector(state => state.present.login.userType)
  const [isLoadingBrandAlign, setIsLoadingBrandAlign] = useState(false)
  const [isSendingEmails, setIsSendingEmails] = useState(false)

  useEffect(() => {
    if (userType === UserType.Admin) {
      dispatch(setAdminCurrentPage(MENUKEYS.Dashboard))
    }
  }, [dispatch, userType])

  const callAlignBrands = async () => {
    setIsLoadingBrandAlign(true)
    const result = await alignBrands()
    if (isValidResult(result)) {
      notification.success({
        message: `${label_OperationCompleted}`,
        duration: 5,
      })
    }
    setIsLoadingBrandAlign(false)
  }

  const sendEmailToMarketplaces = async () => {
    setIsSendingEmails(true)
    const result = await sendMarketplaceInvitations()
    if (isValidResult(result)) {
      notification.success({
        message: `${label_OperationCompleted}`,
        duration: 5,
      })
    }
    setIsSendingEmails(false)
  }

  return userType === UserType.Admin ? (
    <>
      <Row className='pr-6 pt-5 pl-6'>
        <Col>
          <Button type='primary' loading={isLoadingBrandAlign} onClick={callAlignBrands}>
            {label_AlignBrands}
          </Button>
        </Col>
      </Row>
      <Row className='pr-6 pt-5 pl-6'>
        <Col>
          <Button type='primary' loading={isSendingEmails} onClick={sendEmailToMarketplaces}>
            {label_SendMarketplaceInvitation}
          </Button>
        </Col>
      </Row>
    </>
  ) : null
}
