import { createSlice } from '@reduxjs/toolkit'
import { MarketplaceData } from '../Interfaces/MarketplaceInterfaces'

export const marketplaceSlice = createSlice({
  name: 'marketplace',
  initialState: {
    currentMarketplace: null as MarketplaceData,
    currentPage: '',
    editedFields: {},
  },
  reducers: {
    setMarketplace: (state, action) => {
      state.currentMarketplace = action.payload
    },
    setMarketplaceCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    editedMarketplaceFields: (state, action) => {
      const fieldName = action.payload.fieldName
      const content = action.payload.content
      const alreadyEditedFields = state.editedFields.hasOwnProperty(fieldName)
      if (alreadyEditedFields) {
        console.log(content, state.currentMarketplace[fieldName].toString())
        if (content === state.currentMarketplace[fieldName].toString()) {
          const updatedEditedFields = { ...state.editedFields }
          delete updatedEditedFields[fieldName]
          state.editedFields = updatedEditedFields
        } else {
          const alreadyEditedFields = { ...state.editedFields }
          alreadyEditedFields[fieldName] = content
          state.editedFields = alreadyEditedFields
        }
      } else {
        state.editedFields = {
          ...state.editedFields,
          [fieldName]: content,
        }
      }
    },
    resetMarketplaceEdit: state => {
      state.editedFields = {}
    },
  },
})

export const { setMarketplaceCurrentPage, setMarketplace, editedMarketplaceFields, resetMarketplaceEdit } =
  marketplaceSlice.actions
export default marketplaceSlice.reducer
