import CryptoJS from 'crypto-js/crypto-js'
import { isEncoded } from './GenericHelper'

const encrypt = (message: string, passPhrase: string): string => {
  const encrypted = CryptoJS.AES.encrypt(message, passPhrase)

  //return openssl encrypted string
  return encrypted
}

const decrypt = (encrypted: string | null, passPhrase: string): string => {
  if (encrypted) {
    const decrypted = CryptoJS.AES.decrypt(encrypted, passPhrase)

    return decrypted.toString(CryptoJS.enc.Utf8) // message
  }

  return null
}

const generatePassPhrase = (serverConfig: string, userValue: string): string => {
  if (serverConfig && !isEncoded(serverConfig)) {
    serverConfig = encodeURIComponent(serverConfig)
  }
  return serverConfig + userValue
}

export { encrypt, decrypt, generatePassPhrase }
