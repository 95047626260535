import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import undoable from 'redux-undo'
import { isProdSite } from './Helpers/GenericHelper'
import loginReducer from './Slices/LoginSlice'
import mainReducer from './Slices/MainSlice'
import sellerReducer from './Slices/SellerSlice'
import marketplaceReducer from './Slices/MarketplaceSlice'
import adminReducer from './Slices/AdminSlice'

const store = configureStore({
  devTools: !isProdSite(),
  reducer: undoable(
    combineReducers({
      login: loginReducer,
      main: mainReducer,
      seller: sellerReducer,
      marketplace: marketplaceReducer,
      admin: adminReducer,
    }),
  ),
})

export default store
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
