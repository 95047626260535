import { useParams } from 'react-router-dom'
import { SellerData } from '../../Interfaces/SellerInterfaces'
import { useEffect, useState } from 'react'
import { isValidResult } from '../../Helpers/ResultHelper'
import { enableSeller, getSellerData, saveSellerData } from '../../Services/SellerService'
import { NEW_ELEMENT_ID } from '../../Constants/Configuration'
import { Content } from 'antd/es/layout/layout'
import { Button, Col, Row, Spin } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import { label_mrkpSheetLeft_pdf, label_seller_eyeDescription, label_sellerContacts } from '../../Constants/Labels'
import SellerForm from './Components/SellerForm'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { setSellerCurrentPage, setSeller, resetSellerEdit } from '../../Slices/SellerSlice'
import { UploadFile, deleteFile, uploadFile } from '../../Services/UploadService'
import { UserType } from '../../Interfaces/LoginInterfaces'
import { MENUKEYS } from '../../Constants/ClientRoutes'
import PdfLogo from '../../Svg/PdfLogo'
import { MailData } from '../Components/MailDialog'
import ContactIcon from '../../Svg/ContactIcon'
import { MarketplaceData } from '../../Interfaces/MarketplaceInterfaces'
import MailDialog from '../Components/MailDialog'
import { getMarketplaceData } from '../../Services/MarketplaceService'
import { setMarketplace, setMarketplaceCurrentPage } from '../../Slices/MarketplaceSlice'
import { setAdminCurrentPage } from '../../Slices/AdminSlice'
import { useNavigateWrapper } from '../../Helpers/NavigateHelper'

export default function SellerSheet() {
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const entityId: number = useAppSelector(state => state.present.login.entityId)
  const [currentSeller, setCurrentSeller] = useState<SellerData>()

  const currentMarketplace: MarketplaceData = useAppSelector(state => state.present.marketplace.currentMarketplace)
  const editedFields = useAppSelector(state => state.present.seller.editedFields)
  const userType: UserType = useAppSelector(state => state.present.login.userType)
  const [isLoading, setIsLoading] = useState(false)
  const [mailData, setMailData] = useState<MailData>(null)

  const onSubmitHandler = async (values: SellerData, pdf: File) => {
    values.id = parseInt(id)
    setIsLoading(true)
    const response = await saveSellerData(values)

    if (isValidResult(response)) {
      if (pdf) {
        await uploadFile(pdf, UploadFile.SellerPdf, values.id)
      } else {
        if (editedFields['pdf'] === '') {
          await deleteFile(values.pdf, UploadFile.SellerPdf, values.id)
        }
      }
    }
    getSellerData(parseInt(id)).then(seller => {
      if (isValidResult(seller)) {
        dispatch(setSeller(seller.payload))
        dispatch(resetSellerEdit())
        setCurrentSeller(seller.payload)
      }
    })
    setIsLoading(false)
  }
  const onEnabledHandler = async (value: boolean) => {
    setIsLoading(true)
    const response = await enableSeller(parseInt(id), value)
    if (isValidResult(response)) {
      const seller = { ...currentSeller, isEnabled: value }
      dispatch(setSeller(seller))
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (parseInt(id) !== NEW_ELEMENT_ID) {
      getSellerData(parseInt(id)).then(seller => {
        if (isValidResult(seller)) {
          dispatch(setSeller(seller.payload))
          setCurrentSeller(seller.payload)
        }
      })
    }
  }, [id, dispatch])

  useEffect(() => {
    if (userType === UserType.Seller) {
      dispatch(setSellerCurrentPage(MENUKEYS.SellerSheet))
    }
  }, [dispatch, userType])

  useEffect(() => {
    if (userType === UserType.Marketplace && entityId) {
      getMarketplaceData(entityId).then(mrkp => {
        if (isValidResult(mrkp)) dispatch(setMarketplace(mrkp.payload))
      })
      dispatch(setMarketplaceCurrentPage(MENUKEYS.Sellers))
    }
    if (userType === UserType.Admin) {
      dispatch(setAdminCurrentPage(MENUKEYS.Sellers))
    }
  }, [entityId, userType, dispatch])

  const handleMailClick = e => {
    e.preventDefault()
    if (currentSeller.email) {
      setMailData({
        isOpen: true,
        sellerName: currentSeller.businessName,
        marketplaceId: currentMarketplace.id,
        sellerId: currentSeller.id,
      })
    }
  }

  return (
    <>
      <Content className='page-content bg-white' id='page-content-seller-sheet'>
        {!currentSeller ? (
          <Row justify='center' align='middle' style={{ height: '50vh' }} className='bg-white'>
            <Spin />
          </Row>
        ) : (
          <Row justify='center' align='top' style={{ height: '100%' }}>
            <Col span={4} className='sheet-text pt-6 '>
              <div className='d-flex flex-column align-end sheet-left'>
                <span className='mb-6 brand-name'>{currentSeller.businessName}</span>
                {currentSeller.pdf ? (
                  <div className='pdf-info d-flex flex-column align-end mb-6'>
                    <a
                      href={`/repo/seller${currentSeller.id}/${currentSeller.pdf}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <PdfLogo />
                    </a>
                    <span>{label_mrkpSheetLeft_pdf}</span>
                  </div>
                ) : null}
                {userType === UserType.Marketplace &&
                (currentMarketplace.contactEmail || currentMarketplace.affiliationLink) ? (
                  <>
                    <a onClick={handleMailClick} target='_blank' rel='noopener noreferrer'>
                      <ContactIcon />
                    </a>
                    <span>{label_sellerContacts}</span>
                  </>
                ) : null}
              </div>
            </Col>
            <Col className='pt-6' span={14}>
              {currentSeller && (
                <Spin spinning={isLoading}>
                  <SellerForm currentSeller={currentSeller} onSubmit={onSubmitHandler} onEnable={onEnabledHandler} />
                  {userType !== UserType.Marketplace && (
                    <div className='d-flex gap-8 pb-6'>
                      <EyeOutlined />
                      <span> {label_seller_eyeDescription} </span>
                    </div>
                  )}
                </Spin>
              )}
            </Col>
            <Col span={4}></Col>
          </Row>
        )}
      </Content>
      <MailDialog
        emailTo='seller'
        contactEmail={currentMarketplace?.contactEmail}
        affiliationLink={currentMarketplace?.affiliationLink}
        mailData={mailData}
        onClose={() => setMailData({ isOpen: false })}
      />
    </>
  )
}
